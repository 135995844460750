@supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      --active: #7a932c;
      --active-inner: #7a932c;
      --focus: 2px #7a932c;
      --border: #696b62;
      --border-hover: #7a932c;
      --background: none;
      height: 24px;
      outline: none;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 24px;
        height: 24px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        vertical-align: top;
      }
    }
    input[type='checkbox'] {
    &:not(.switch) {
      &:after {
        width: 7px;
        height: 20px;
        border: 2px solid #696b62;
        border-top: 0;
        border-left: 0;
        left: 9px;
        top: 0px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      &:after {
        left: 2px;
        top: 2px;
        width: 15px;
        height: 15px;
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
    }
  }
  }