@import '../../../../styles/initstyles.scss';

.table-row {
  display: flex;
  min-width: 100%;
  height: 50px;
  margin: 10px 0;

  @media (max-device-width: 480px) {
    margin: 10px 0 50px;
    > div:nth-child(4) {
      position: relative;
      min-width: 0;
    }
  }

  > div:nth-child(3) {
    @media (max-device-width: 480px) {
      display: none;
    }
  }
}

.request-button {
  background-color: #B5C15D;
  width: 180px;
  margin-left: 19%;
  height: 44px;
  transition: all .3s ease;
  &:hover {
    background-color: $darkGreen;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    margin-left: -18px;
  }
  @media (max-device-width: 768px) {
    width: 150px;
  }
  @media (max-device-width: 480px) {
    position: absolute;
    right: 120px;
    top: 55px;
    width: 220px;
  }
  @media (max-device-width: 320px) {
    right: 50px;
  }
}

.button-span {
  font-family: Ubuntu;
  color: $lightYellow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
}

.table-cell {
  min-width: 22%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-device-width: 768px) {
    min-width: 24%;
  }
  @media (max-device-width: 480px) {
    min-width: 50%;
  }
}

.table-cell-span {
  padding-left: 15%;
  padding-right: 10px;
  @media (max-device-width: 480px) {
    min-width: 80px;
  }
}

.table-cell-bank {
  min-width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-device-width: 768px) {
    min-width: 24%;
  }
  @media (max-device-width: 480px) {
    justify-content: center;
    min-width: 50%;
  }
}

.img-wrapper {
  padding-left: 35%;
  @media (max-device-width: 768px) {
    padding-left: 24%;
  }
  @media (max-device-width: 480px) {
    padding-left: 0;
  }
}
