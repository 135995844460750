@import '../../styles/initstyles.scss';

@media (max-device-width: 768px) {
  .monitoring-services .ts-block {
    flex-wrap: wrap;
  }
}

.mobile-price-selector {
  display: none;
  @media (max-device-width: 768px) {
    display: block;
    padding-left: 12px;
  }
}

.monitoring {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 1143px;
  margin: 0 auto;
}

@media (min-device-width: 769px) {
  .monitoring {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 1143px;
    margin: 0 auto;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .monitoring {
    min-width: 670px;
    @include flexCol;
    p {
      font-size: 14px;
      line-height: 22.8px;
      padding: 0;
      margin-bottom: 30px;
      text-align: justify;
    }
  }

  .info-text {
    padding-top: 15px;
    @include flexCol;
    p {
      font-size: 14px;
      line-height: 22.8px;
      padding: 0;
      margin-bottom: 30px;
      text-align: justify;
    }
  }
}

@media (min-device-width: 1200px) {
  .monitoring .info-block,
  .monitoring .monitoring-services {
    align-self: center;
    margin-bottom: 120px;
  }

  .monitoring .info-text {
    width: 100%;
    max-width: 740px;
    height: 202px;
    padding-top: 25px;
    @include flexCol;
    justify-content: space-between;
    p {
      padding: 0;
      margin-bottom: 40px;
    }
  }

  .monitoring-services .ts-block .ts-name {
    padding-left: 1%;
  }
}

@media (min-device-width: 1440px) {
  .monitoring-services .ts-service {
    padding-left: 10px;
    width: 30%;
  }

  .monitoring-services .ts-price {
    padding-left: 116px;
  }
}

@media (max-device-width: 1024px) {
  @media (max-device-width: 1024px){
    min-width: 500px;
    @media (max-device-width: 320px) {
      min-width: 0;
    }
  }
}

@media (max-device-width: 320px) {
  .info-block {
    padding: 16px 19px 20px;
  }
}
