@import '../../styles/initstyles.scss';

.calc-header {
  @include textLeft;
  > p {
    margin-left: 20%;
  }
}

.date-range-wrapper,
.rdrCalendarWrapper {
  background: $lightYellow;
}

.rdrMonthAndYearWrapper {
  button {
    background: $lightYellow;
  }
}

.rdrDateRangeWrapper {
  background: $lightYellow;
   .rdrDateDisplayItemActive {
    border: 1px solid $darkGreen
  }
}

.BG-container {
  @media (max-device-width: 768px) {
    max-width: 768px;
  }
  @media (max-device-width: 320px) {
    max-width: 320px;
  }
}

.bank-guarantee-calculator {
  margin-top: 48px;
  @media (max-device-width: 1920px) {
    //margin-bottom: 124px;
  }
  @media (max-device-width: 768px) {
    //margin-bottom: 84px;
  }
}

.filter-button {
  position: relative;
  padding-left: 7px;
  &:after {
    position: absolute;
    right: -23px;
    top: 1px;
    content: '';
    display: block;
    width: 8px;
    height: 14px;
    background-image: url(../../styles/filter-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.tbg-button {
  position: absolute;
  right: 10px;
  bottom: 13px;
}

.calc-header {
  &:first-child {
    background: $green;
  }
}

.err-notification {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #CA3027;
  padding-top: 5px;
}

.calc-button {
  @include buttonActions;
  transition: all .3s ease;
  &:disabled {
    cursor: not-allowed;
  }
  @media (min-device-width: 1200px) {
    @include uRegular16;
    margin-left: 0;
    width: 214px;
    height: 52px;
  }
  @media (max-device-width: 1199px) {
    @include uRegular16;
    width: 214px;
    height: 52px;
  }
  @media (max-device-width: 768px) {
    @include uRegular16;
    margin: 30px 0 50px;
    width: 303px;
    height: 50px;
  }
  @media (max-device-width: 320px) {
    width: 280px;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}
 
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

.summ-input {
  border: 1px solid #B5C15D;
  &:hover {
    border: 1px solid $darkGreen;
  }
}

.period-input {
  border: 1px solid #B5C15D;
  @include flexRow;
  justify-content: flex-end;
  display: flex;
  @media (min-width: 1200px) {
    width: 230px;
    align-items: center;
    @include uRegular14;
    padding-left: 6px;
    height: 46px;
    border: 1px solid $darkGreen;
  }
  @media (max-width: 1199px) {
    padding-left: 10px;
    width: 230px;
    height: 46px;
    align-items: center;
  }
  @media (max-width: 768px) {
    margin-top: 24px;
    width: 197px;
    height: 32px;
  }
  &:hover {
    border: 1px solid $darkGreen;
  }
}

.err-period-input {
  @extend .period-input;
  border-color: #CA3027;
  &:hover {
    border-color: #CA3027;
  }
}

.gc-summ-section {
  @include flexRow;
  padding-top: 0.25px;
}

.summ-err {
  color: $redError;
  max-width: 230px;
  @include uRegular;
}

.itog {
  position: sticky;
  @media (min-device-width: 1200px) {
    position: sticky;
    @include flexRow;
  }
  @media (max-device-width: 1199px) {
    display: flex;
    width: 264px;
    align-items: center;
  }
  @media (max-device-width: 768px) {
    display: flex;
    align-items: center;
    position: absolute;
    left: 19px;
    top: 115px;
  }
}

.itog div {
  @media (min-device-width: 1200px) {
    width: 170px;
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-right: 10px;
    background-color: $lightYellow;
    @include uRegular18;
  }
  @media (max-device-width: 1199px) {
    width: 230px;
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-right: 10px;
    background-color: $lightYellow;
    @include uRegular18;
  }
  @media (max-device-width: 768px) {
    width: 197px;
    height: 32px;
    padding-left: 10px;
    background-color: $lightYellow;
    @include uRegular18;
  }
  @media (max-device-width: 320px) {
    width: 178px;
    height: 52px;
    padding: 12px 0 12px 10px;
  }
}

.itog span {
  @media (max-device-width: 1920px) {
    padding-right: 23px;
  }
  @media (min-device-width: 1200px) {
    padding-right: 10px;
    @include uRegular18;
    align-self: center;
  }
  @media (max-device-width: 1199px) {
    @include uRegular18;
    padding-right: 35px;
  }
  @media (max-device-width: 768px) {
    @include uRegular14;
  }
}

.itog svg {
  @media (min-device-width: 1200px) {
    align-self: center;
    padding: 0;
  }
}

%bg-co {
  color: $black;
  @include textLeft;
  font-size: 18px;
  border: none;
  &:hover {
    color: $darkGreen;
  }
}

.bg-control {
  @extend %bg-co;
  background: $yellow;
  @media (max-device-width: 768px) {
    width: 303px;
    min-height: 40px;
    margin: 0;
    @include uRegular14;
  }
}

.bg-option {
  padding: 8px 10px 20px 8px;
}

.bg-menu {
  @extend %bg-co;
  background: $lightYellow;
  .is-selected {
    background: none;
  }
}

.Dropdown-arrow {
  border-color: $darkGreen transparent transparent transparent;
}

.period-dates {
  @include flexRow;
  justify-content: center;
}

.period-section {
  justify-content: center;
  @include flexRow;
  @media (min-device-width: 1200px) {
    height: 100px;
    padding: 0 20px;
  }
  @media (max-device-width: 1199px) {
    height: 100px;
    padding: 0;
  }
  @media (max-device-width: 768px) {
    justify-content: flex-start;
  }
}

.open-calendar {
  background: $white;
}

.notification-wrapper {
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 333px;
  min-width: 644px;
  background-color: $lightYellow;
  @media (max-width: 768px) {
    min-width: 500px;
  }
  @media (max-width: 480px) {
    min-width: 400px;
  }
  @media (max-width: 320px) {
    min-width: 0;
  }
}

.notification-container {
  font-family: Ubuntu;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 247px;
  min-height: 168px;
  > div {
    margin-bottom: 24px;
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}

.send-icon {
  width: 40px;
  height: 40px;
  background-color: #7a932c;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../src/styles/sendIcon.png');
}

.notification-title {
  color: #7A932C;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
}

.notification-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: center;
  max-width: 277px;
}

.mod-fin {
  border-radius: 2px;
  background: $lightYellow;
  form {
    input {
      background: $lightYellow;
    }
  }
}

.mod-fin-policy {
  input[type=checkbox] {
    width: 24px;
    height: 24px;
    margin: 0;
  }
  label div {
    line-height: normal;
  }
  .policy {
    color: $darkGreen;
  }
}

.mod-fin-policy-err {
  @include fAlignCenter;
  input[type=checkbox] {
    width: 24px;
    height: 24px;
    margin: 0;
    border: 1px solid $redError;
  }
  label div {
    line-height: normal;
  }
  .policy {
    color: $darkGreen;
  }
}

.mod-fin-header {
  font-weight: bold;
  text-align: center;
  @include uRegular;
}

.mod-fin-policy {
  @include fAlignCenter;
}

.PhoneInput {
  margin-top: 10px;
  input {
    border: 1px solid $darkGrey;
    &:focus {
      border-color: $salatGreen;
    }
  }
}

.ph-err {
  .PhoneInputInput {
    border: 1px solid $redError;
  }
}

.mod-fin-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    @include buttonActions;
  }
}

.period-fin {
  border-radius: 4px;
  background: $lightYellow;
  padding: 8px 15px;
  @media (max-device-width: 1920px) {
    left: -270px;
  }
  @media (min-device-width: 1200px) {
    @include flexCol;
    position: absolute;
  }
  @media (max-device-width: 1199px) {
    @include flexCol;
    position: absolute;
    left: -220px;
  }
  @media (max-device-width: 768px) {
    @include flexCol;
    position: absolute;
    left: -160px;
  }
  @media (max-device-width: 480px) {
    padding: 8px 10px;
    left: -100px;
  }
  @media (max-device-width: 320px) {
    left: -180px;
    max-width: 300px;
  }
}

.period-fin-actions {
  justify-content: flex-end;
  padding: 0 10px 8px 25px;
  .calend-dates {
    padding-bottom: 0;
  }
  button {
    width: 171px;
    height: 38px;
    @include buttonActions;
  }
  @media (max-device-width: 1199px) {
    @include vhCenter;
    button {
      width: 171px;
      height: 38px;
      @include buttonActions;
    }
  }
  @media (min-device-width: 769px) {
    @include vhCenter;
    justify-content: flex-end;
    button {
      width: 171px;
      height: 38px;
      @include buttonActions;
    }
  }
  @media (max-device-width: 768px) {
    justify-content: flex-end;
  }
  @media (max-device-width: 320px) {
    min-width: 300px;
  }
}

button.rdrNextPrevButton {
  background-image: url(../../styles/min.svg);
  width: 28px;
  height: 28px;
  border-radius: 2px;
  background-color: $darkGreen;
  &:enabled {
    color: $lightYellow;
    background: $darkGreen;
  }
  &:enabled:hover {
    background: $green;
  }  i {
    display: none;
  }
}

button.rdrNextPrevButton:nth-child(1) {
  background-image: url(../../styles/min2.svg);
  transform: rotate(180deg);
  width: 28px;
  height: 28px;
  border-radius: 2px;
  i {
    display: none;
  }
}

button.rdrNextPrevButton:nth-child(3) {
  background-image: url(../../styles/min2.svg);
  width: 28px;
  height: 28px;
  border-radius: 2px;
  i {
    display: none;
  }
}

.rdrStartEdge,
.rdrEndEdge {
  border-radius: 2px;
  background-color: $darkGreen !important;
  span {
    border-radius: 2px;
    background-color: $darkGreen !important;
  }
}

.rdrInRange {
  border-radius: 2px;
  background-color: $aYellow !important;
  span {
    border-radius: 2px;
    background-color: $aYellow !important;
  }
}

.summ-itog-header {
  @media (max-device-width: 768px) {
    @include uRegular14;
    padding-right: 35px;
  }
}

.calc-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 60px;
}

.summ-header {
  @media (width: 1920px) {
    padding-right: 15px;
  }
  @media (min-width: 1200px) {
    @include uRegular18;
    padding-right: 10px;
    padding-top: 7px;
  }
  @media (max-width: 1199px) {
    font-size: 18px;
    padding-right: 10px;
    padding-top: 11px;
  }
  @media (max-width: 768px) {
    @include uRegular14;
    padding-right: 35px;
    padding-top: 5px;
  }
}

.sum-container {
  display: flex;
  justify-content: space-between;
  min-width: 1000px;
  @media (max-device-width: 1024px) {
    min-width: 0;
    flex-wrap: wrap;
  }
  @media (max-device-width: 768px) {
    min-width: 100%;
    flex-direction: column;
    padding-left: 18px;
  }
}

.input-container {
  display: flex;
}

.period-header {
  @media (min-device-width: 1920px) {
    padding-right: 15px;
  }
  @media (min-device-width: 1200px) {
    @include uRegular18;
    padding: 10px 35px 10px 10px;
  }
  @media (max-device-width: 1199px) {
    font-size: 18px;
    padding: 10px;
  }
  @media (max-device-width: 768px) {
    @include uRegular14;
    padding: 30px 28px 0 0;
  }
}

.rdrDayHovered {
  span {
    border-radius: 2px;
    color: $darkGreen !important;
  }
}

.rdrDay:not(.rdrDayPassive) .rdrDayNumber {
  color: $black !important;
  span {
    color: $black !important;
  }
}

.guarantee-calculator {
  @media (min-device-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  @media (max-device-width: 1199px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;
    padding: 0 20px 0 0;
  }
  @media (max-device-width: 768px) {
    padding: 15px 19px 0 0;
    margin-top: 16px;
    flex-wrap: wrap;
    @include flexCol;
    align-items: flex-start;
  }
}

@media (max-device-width: 768px) {
  .finservices {
    .ts-name {
      width: 50%;
    }
    .mobile {
      width: 50%;
      padding-left: 10px;
    }
    .ts-block {
      flex-wrap: wrap;
    }
    .bg-notification {
      font-size: 14px;
      line-height: 130%;
      padding: 30px 0 2px 19px;
    }
    .input-container {
      flex-direction: column;
    }
  }

  .tbg-button {
    right: 14%;
    bottom: -126px;
  }

  .calc-header {
    height: 47px;
    justify-content: center;
    @include flexCol;
    p {
      @include textLeft;
      @include uMedium18;
      padding-right: 7px;
      padding-left: 20px;
    }
  }

  .calc-header > p:nth-child(1) {
    margin-top: 44px;
  }

  .calc-about {
    @include uRegular12;
    padding: 45px 19px 24px 19px;
    @include textLeft;
  }

  .bg-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 19px;
  }
  
  .bg-option {
    @include uRegular14;
    width: 280px;
    height: 60px;
    @include textLeft;
  }

  .bg-menu {
    width: 303px;
    min-height: 40px;
    margin: 0;
    @include uRegular14;
  }

  .summ-input {
    width: 197px;
    height: 32px;
  }

  .summ-err {
    font-size: 14px;
  }

  .err-summ-input {
    width: 197px;
    height: 32px;
    border: 1px solid $redError;
  }

  .period-dates {
    font-size: 14px;
    align-items: center;
    padding-left: 5px;
  }

  .calend-dates {
    display: flex;
    margin-right: 27px;
    padding-bottom: 20px;
    .calend-start {
      padding: 0px 4px;
    }
    .calend-end {
      padding-left: 4px;
    }
    @include uRegular14;
  }

  .open-calendar svg {
    width: 24px;
    height: 24px;
  }

  .mod-fin {
    @include flexCol;
    width: 268px;
    .mod-fin-img-container {
      display: none;
    }
    form {
      padding: 0 24px 24px 24px;
      input {
        width: 100%;
        height: 32px;
        margin-top: 8px;
      }
    }
  }

  .mod-fin-header {
    font-size: 13px;
    font-family: 'Ubuntubold';
    font-weight: normal;
    text-align: justify;
    padding: 24px 0px;
  }

  .mod-fin-text {
    width: 220px;
    @include uRegular;
    font-size: 12px;
  }

  .mod-fin-policy {
    padding: 17px 0px 24px 0px;
    div {
      @include uRegular;
      font-size: 10px;
      padding-left: 8px;
    }
  }

  .mod-fin-policy-err {
    padding: 17px 0px 24px 0px;
    div {
      @include uRegular;
      font-size: 10px;
      padding-left: 8px;
    }
  }

  .mod-fin-actions {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      height: 40px;
    }
  }

  .no-itog {
    display: none;
    div {
      display: none;
    }
    span {
      display: none;
    }
  }

  .input-container {
    margin-bottom: 60px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .finservices {
    @include flexCol;
    justify-content: center;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
  }

  .bank-services {
    width: 100%;
    max-width: 1143px;
  }

  .calc-bank-guarantee {
    margin: 0 auto;
    min-width: 1143px;
  }

  .BG-container {
    padding: 0 0 0 82px;
  }

  .calc-bank-guarantee > div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }

  .calc-header {
    @include uMedium24;
  }

  .calc-header:first-child {
    width: 404px;
    height: 62px;
    padding-top: 31px;
    margin-top: 0;
  }

  .calc-about {
    padding: 80px 0 38px 0;
  }

  .bg {
    font-size: 18px;
    width: 600px;
  }

  .bg-control {
    @extend .bg;
    @include fAlignCenter;
    height: 67px;
  }

  .bg-menu {
    @extend .bg;
    border: none;
  }

  .Dropdown-arrow {
    margin: 20px;
  }

  .gc-summ-section {
    height: 100px;
  }

  .summ-input {
    padding-left: 10px;
    width: 230px;
    height: 46px;
    border: 1px solid #B5C15D;
    align-items: center;
  }

  .err-summ-input {
    padding-left: 10px;
    width: 230px;
    height: 46px;
    border: 1px solid $redError;
  }

  .calend-dates {
    display: flex;
    margin-right: 27px;
    .calend-start {
      padding: 0px 4px;
    }
    .calend-end {
      padding-left: 4px;
    }
    @include uRegular14;
  }

  .mod-fin {
    @include flexRow;
    width: 664px;
    .mod-fin-img-container {
      margin: 38px;
      @include vhCenter;
    }
    form {
      width: 351px;
      padding-right: 38px;
      input {
        width: 100%;
        margin-top: 5px;
        height: 36px;
      }
    }
  }

  .mod-fin-header {
    font-size: 14px;
    padding: 34px 0px 16px 0px;
  }

  .mod-fin-text {
    @include uRegular12;
  }

  .mod-fin-policy {
    @include uRegular12;
    padding: 7px 0 17px;
    label div {
      padding-left: 21px;
    }
  }

  .mod-fin-policy-err {
    @include uRegular12;
    padding: 7px 0 17px;
    label div {
      padding-left: 21px;
    }
  }

  .mod-fin-actions {
    padding-bottom: 38px;
    button {
      width: 100%;
      height: 38px;
    }
  }

  .no-itog {
    display: none;
    div {
      display: none;
    }
    span {
      display: none;
    }
  }
}

@media (min-device-width: 1200px) {
  .finservices {
    @include flexCol;
    justify-content: center;
  }

  .popup-info {
    border-radius: 2px;
    background-color: #F0F9D4;
    color: $black;
    align-items: center;
  }

  .info-icon {
    cursor: pointer;
  }

  .finservices .info-block,
  .finservices .bank-services,
  .finservices .calc-bank-guarantee,
  .finservices .banks {
    margin: 0 auto;
    max-width: 1143px;
  }

  .finservices .info-text {
    width: 100%;
    max-width: 708px;
    @include flexCol;
    justify-content: space-around;

    p {
      text-align: justify;
    }
  }

  .bank-services .ts-block {
    margin-left: 1%;
  }

  .bank-services .ts-name {
    padding-right: 0;
  }

  .bank-services {
    padding-left: 0;
    margin-left: 14%;
  }

  .ts-price {
    padding-left: 0;
    margin-left: 3%;
  }

  .bank-services .ts-service {
    margin-left: 5%;
    width: 34%;
  }

  .benefits-container {
    max-width: 1143px;
    margin: 0 auto 10px;
  }

  .calc-bank-guarantee {
    margin: 60px auto 0;
    max-width: 1143px;
  }

  .calend-dates {
    display: flex;
    margin-right: 27px;

    .calend-start {
      padding: 0px 4px;
    }

    .calend-end {
      padding-left: 4px;
    }

    @include uRegular12;
  }

  .calc-header {
    p {
      @include uMedium24;
      padding-bottom: 7px;
    }
  }

  .calc-header:first-child {
    width: 435px;
    height: 62px;
    padding-top: 30px;
    margin-top: 60px;
  }

  .calc-about {
    padding: 60px+33px 0px 38px 0px;
  }

  %bg {
    @include uRegular16;
    width: 600px;
  }

  .bg-control {
    @extend %bg;
    @include fAlignCenter;
    height: 67px;
    border-radius: 5px;
    padding-left: 42px;
  }

  .bg-menu {
    @extend %bg;
    padding-top: 28px;
    padding-bottom: 10px;
    border: none;
    height: 166px;
    border-radius: 4px;
  }

  .bg-option {
    @include uRegular16;
    padding: 0px 0px 23px 42px;
  }

  .Dropdown-arrow {
    margin: 20px;
  }

  .calc-bank-guarantee > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding-left: 11%;
  }

  .gc-summ-section {
    height: 100px;
  }

  .summ-input {
    @include uRegular14;
    padding-left: 6px;
    height: 46px;
    border: 1px solid $darkGreen;
  }

  .summ-input {
    width: 200px;
  }

  .err-summ-input {
    @include uRegular14;
    padding-left: 10px;
    width: 200px;
    height: 46px;
    border: 1px solid $redError;
  }

  .mod-fin-policy {
    @include uRegular12;
    padding: 12px 0px 18px 0px;

    label {
      padding-left: 31px;

      div {
        @include uRegular12;
      }
    }
  }

  .mod-fin-policy-err {
    @include uRegular12;
    padding: 12px 0px 18px 0px;

    label {
      padding-left: 31px;

      div {
        @include uRegular12;
      }
    }
  }

  .mod-fin {
    @include flexRow;
    width: 644px;

    .mod-fin-img-container {
      margin: 38px;
      @include vhCenter;
    }

    form {
      width: 275px;

      .mod-fin-text {
        width: 260px;
        @include uRegular12;
        padding-top: 15px;
      }

      input {
        width: 100%;
        margin-top: 9px;
        height: 36px;
      }
    }
  }

  .mod-fin-header {
    font-size: 16px;
    padding-top: 34px;
  }

  .rdrDayToday.rdrDayNumber span::after {
    width: 0px !important;
  }

  .mod-fin-actions {
    padding-bottom: 38px;

    button {
      @include uRegular14;
      width: 100%;
      height: 38px;
    }
  }

  .no-itog {
    display: none;

    div {
      display: none;
    }

    span {
      display: none;
    }
  }
}

@media (device-width: 1920px) {
  .bank-services .ts-block {
    margin-left: 1%;
  }

  .bank-services .ts-price {
  }

  .calc-bank-guarantee > div:nth-child(2) {
    padding-left: 10%;
  }
}

@media (max-device-width: 768px) {
  .bg-dropdown {
    justify-content: flex-start;
  }

  .calc-header {
    > p {
      margin-left: 0;
    }
  }

  .rdrWeekDays,
  .rdrDays {
    width: 262px !important;
  }

  .rdrDayNumber span,
  .rdrWeekDay {
    @include uRegular;
    font-size: 14px !important;
  }

  .rdrMonthName {
    @include uRegular;
    font-size: 16px !important;
  }

  .rdrMonth {
    width: 290px !important;
  }
}

@media (min-device-width: 769px) {
  .date-range-wrapper,
  .rdrCalendarWrapper .rdrDateRangeWrapper,
  .rdrMonthAndYearWrapper {
    width: 560px !important;
  }

  .rdrWeekDays,
  .rdrDays {
    width: 262px !important;
  }

  .rdrDayNumber span,
  .rdrWeekDay {
    @include uRegular;
    font-size: 14px !important;
  }

  .rdrMonthName {
    @include uRegular;
    font-size: 16px !important;
  }

  .rdrMonth {
    width: 280px !important;
  }
}

@media (max-device-width: 320px) {
  .date-range-wrapper,
  .rdrCalendarWrapper,
  .rdrDateRangeWrapper,
  .rdrMonthAndYearWrapper,
  .rdrDefinedRangesWrapper {
    max-width: 300px;
  }

  .mobile .mobile-price svg {
    top: -12px;
  }

  .calend-dates {
    margin: 0 0 20px;
  }

  .bg-control,
  .bg-menu {
    max-width: 280px;
    min-height: 60px;
    @include uRegular14;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Dropdown-arrow {
    top: 26px;
  }
}
