@import '../../styles/initstyles.scss';

.footer {
  align-items: center;
  justify-content: flex-start;
  background-color: $darkGrey;
  padding: 50px 0;
  color: $white;
  p {
    @include textLeft;
    justify-content: center;
    min-width: 212px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;

  .policies {
    padding-left: 76px;
  }
}

.footer-worktime .email {
  display: flex;
  a {
    padding-top: 2px;
  }
  svg {
    stroke: $white;
  }
}

.icon-phone {
  margin-right: 10px;
  margin-top: 2px;
  @media (max-device-width: 320px) {
    margin: 2px 5px 0 0;
  }
  @media (max-device-width: 1920px) {
    display: initial;
  }
}

.phone-number {
  padding-top: 2px;
}

.footer-content-wrapper {
  padding: 0;
  max-width: 1143px;

  @media (max-device-width: 1024px) {
    max-width: 100%;
  }
}

.anydesk {
  font-family: Ubuntu;
  p {
    min-width: 212px;
  }
  button {
    border-radius: 2px;
    @include vhCenter;
    color: inherit;
    height: 44px;
    background: linear-gradient(to right, #BFC966, #7A932C);
  }
  svg {
    margin-left: 30px;
  }

  @media (max-device-width: 320px) {
    min-width: 0;
  }
}

@media (max-device-width: 768px) {
  .footer {
    flex-direction: column-reverse;
    align-items: center;
    font-size: 14px;
    .footer-contacts {
      display: none;
    }
  }

  .anydesk {
    padding-left: 12px;
    button {
      margin: 10px 0 0 0;
    }
  }

  .footer-worktime {
    padding: 20px 0px 20px 0px;
    p {
      text-align: center;
    }
    .email {
      margin-top: 10px;
    }
  }

  .footer-address p {
    text-align: center;
  }

  .footer-address p:first-child,
  .footer-worktime p:first-child,
  .footer-worktime > p:nth-child(2),
  .footer-phone {
    padding-bottom: 20px;
  }

  .footer-phone {
    div {
      @include fAlignCenter;
      font-size: 18px;
    }
    a {
      padding-left: 10px;
    }
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .footer {
    flex-direction: row;
    font-size: 14px;
  }

  .footer-address p {
    width: 238px; 
  }

  .anydesk {
    p {
      min-width: 220px;
    }
    button {
      margin-top: 20px;
    }
  }

  .contacts {
    margin-left: 10%;
  }

  .footer-worktime {
    margin-left: 8%;
    > p:nth-child(3) {
      padding: 48px 0px 27px 0px;
    }
  }

  .footer-address p:first-child,
  .footer-worktime p:first-child {
    padding-bottom: 20px;
  }

  .footer-phone {
    padding: 45px 0 49px;
    div {
      @include fAlignCenter;
      font-size: 26px;
    }
  }

  .footer-contacts {
    display: flex;
    justify-content: center;
    padding-bottom: 81px;
    .contacts-logos {
      width: 155px;
      justify-content: space-between;
    }
  }
}

@media (min-device-width: 1200px) {
  .footer {
    flex-direction: row;
    justify-content: start;
    font-size: 14px;
  }

  .footer-address p {
    width: 238px; 
  }

  .anydesk {
    button {
      margin-top: 20px;
    }
  }

  .contacts {
    margin-left: 10%;
  }

  .footer-worktime {
    margin-left: 12%;
    > p:nth-child(3) {
      padding: 48px 0px 27px 0px;
    }
  }

  .footer-address p:first-child,
  .footer-worktime p:first-child {
    padding-bottom: 20px;
  }

  .footer-phone {
    padding: 45px 0px 49px 0px;
    div {
      @include fAlignCenter;
      font-size: 26px;
    }
    a {
      margin-left: 10px;
    }
  }

  .footer-contacts {
    display: flex;
    justify-content: center;
    padding-bottom: 81px;
    .contacts-logos {
      width: 155px;
      justify-content: space-between;
    }
  }
}

@media (max-device-width: 993px) {
  .footer-logo {
    display: none;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .anydesk {
    padding-left: 12px;
    div {
      min-width: 210px;
    }
    @media (max-device-width: 768px) {
      padding-left: 2%;
    }
    @media (max-device-width: 320px) {
      padding-left: 0;
    }
  }
}
