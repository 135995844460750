$mobileWidth: 280px;
$darkGreen: #7a932c;
$green: #b5c15d;
$yGreen: #95a843;
$salatGreen: #8db53b;
$lightYellow: #f0f9d4;
$yellow: #dff892;
$aYellow: #cad970;
$darkGrey: #696b62;
$white: #ffffff;
$lightGrey: #8d8d8a;
$redError: #ca3027;
$black: #000000;
$hoverColor: #afbe80;

$mainColor: #494a45;
$pale: #d1d2d1;
$secondColor: #a4a4a2;
$thirdColor: #d5e25b;
$backgroundPale: #f8f8f8;

@mixin fontSizeTitle {
  font-size: 18px;
  line-height: (28/18)*100%;
}

.err {
  border: 1px solid $redError;
  &:focus {
    border: 1px solid $darkGreen;
  }
}

.footnote {
  display: inline-block;
  font-size: 14px;
  color: $lightGrey;
  padding-left: 1%;
  @media (max-width: 1199px) {
    padding-left: 42px;
  }
  @media (max-width: 768px) {
    padding: 20px 3% 0;
  }
}

@font-face {
  font-family: 'RobotoRegular';
  src: url(../fonts/Roboto-Regular.ttf);
}

@mixin rRegular {
  font-family: 'RobotoRegular';
}

@font-face {
  font-family: 'Ubuntubold';
  src: url(../fonts/Ubuntubold.ttf);
}

@mixin uBold {
  font-family: 'Ubuntubold';
  line-height: 140%;
}

@mixin uBold144 {
  font-family: 'Ubuntubold';
  font-size: 124px;
  line-height: 180%;
}

@font-face {
  font-family: 'Ubuntumedium';
  src: url(../fonts/Ubuntumedium.ttf);
}

@mixin uMedium16 {
  font-family: 'Ubuntumedium';
  font-size: 16px;
}

@mixin uMedium18 {
  font-family: 'Ubuntumedium';
  font-size: 18px;
}

@mixin uMedium24 {
  font-family: 'Ubuntumedium';
  font-size: 24px;
}

@font-face {
  font-family: 'Ubunturegular';
  src: url(../fonts/Ubunturegular.ttf);
}

@mixin uRegular30 {
  font-family: 'Ubunturegular';
  font-size: 30px;
  line-height: 150%;
}

@mixin uRegular24 {
  font-family: 'Ubunturegular';
  font-size: 24px;
  line-height: 150%;
}

@mixin uRegular {
  font-family: 'Ubunturegular';
}

@mixin uRegular18 {
  font-family: 'Ubunturegular';
  font-size: 18px;
  line-height: 150%;
}

@mixin uRegular16 {
  font-family: "Ubunturegular";
  font-size: 16px;
}

@mixin uRegular15 {
  font-family: "Ubunturegular";
  font-size: 15px;
  line-height: 130%;
}

@mixin uRegular14 {
  font-family: "Ubunturegular";
  font-size: 14px;
}

@mixin uRegular12 {
  font-family: "Ubunturegular";
  font-size: 14px;
  line-height: 130%;
}

@font-face {
  font-family: 'Robotomedium';
  src: url(../fonts/Robotomedium.ttf);
}

@mixin rMedium {
  font-family: 'Robotomedium';
  color: $black;
}

@mixin rMedium16 {
  font-family: 'Robotomedium';
  font-size: 16px;
  color: $black;
}

@mixin rMedium18 {
  font-family: 'Robotomedium';
  font-size: 18px;
}

@mixin rMedium40 {
  font-family: 'Robotomedium';
  font-size: 40px;
  color: $salatGreen;
}

@font-face {
  font-family: 'Robotobold';
  src: url(../fonts/Robotobold.ttf);
}

@mixin rBold {
  font-family: 'Robotobold';
  color: $darkGreen;
}

@mixin maxWidth {
  width: 100%;
  max-width: 1143px;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin textLeft {
  text-align: justify;
}

@mixin fAlignCenter {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 25px;
}

@mixin vhCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

@mixin bottomShadow {
  box-shadow: 0 3px 3px -3px $lightGrey;
}

@mixin rtbBorderRadius {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

@mixin linearGradientLeft {
  background: linear-gradient(to left, $aYellow, $yGreen, $salatGreen);
}

@mixin linearGradientRight {
  background: linear-gradient(to right, $aYellow, $yGreen, $salatGreen);
}

@mixin linearGradientRightWhite {
  background: linear-gradient(to right, $white, $salatGreen, $yGreen);
}

@mixin sectionHeaders {
  @include uRegular24;
  @include rtbBorderRadius;
}

@mixin buttonActions {
  border-radius: 2px;
  color: $lightYellow;
  background: $darkGreen;
  transition: all .3s ease;
  &:enabled {
    color: $lightYellow;
    background: $darkGreen;
  }
  &:enabled:hover {
    background: $green;
  }
}

.privacy-container {
  $pT: 32px;
  $pR: 50px;
  $pB: 30px;
  $pL: 105px;
  @media (max-width: 3840px) {
    $pT: calc(32px + (64 - 32) * ((100vw - 1080px) / 2160));
    $pR: calc(50px + (92 - 50) * ((100vw - 1080px) / 2160));
    $pB: calc(30px + (60 - 30) * ((100vw - 1080px) / 2160));
    $pL: calc(105px + (210 - 105) * ((100vw - 1080px) / 2160));
    padding: $pT $pR $pB $pL;
  }
  @media (max-width: 1920px) {
    $pT: calc(10px + (32 - 10) * ((100vw - 720px) / 1200));
    $pR: calc(10px + (50 - 10) * ((100vw - 720px) / 1200));
    $pB: calc(10px + (30 - 10) * ((100vw - 720px) / 1200));
    $pL: calc(20px + (105 - 20) * ((100vw - 720px) / 1200));
    padding: $pT $pR $pB $pL;
  }
}

.privacy-short-bottom-container {
  $pT: 32px;
  $pR: 50px;
  $pL: 105px;
  @media (max-width: 3840px) {
    $pT: calc(32px + (64 - 32) * ((100vw - 1080px) / 2160));
    $pR: calc(50px + (92 - 50) * ((100vw - 1080px) / 2160));
    $pL: calc(105px + (210 - 105) * ((100vw - 1080px) / 2160));
    padding: $pT $pR 0 $pL;
  }
  @media (max-width: 1920px) {
    $pT: calc(10px + (32 - 10) * ((100vw - 720px) / 1200));
    $pR: calc(10px + (50 - 10) * ((100vw - 720px) / 1200));
    $pL: calc(20px + (105 - 20) * ((100vw - 720px) / 1200));
    padding: $pT $pR 0 $pL;
  }
}

.privacy-short-top-container {
  $pR: 50px;
  $pB: 30px;
  $pL: 105px;
  @media (max-width: 3840px) {
    $pR: calc(50px + (92 - 50) * ((100vw - 1080px) / 2160));
    $pB: calc(30px + (60 - 30) * ((100vw - 1080px) / 2160));
    $pL: calc(105px + (210 - 105) * ((100vw - 1080px) / 2160));
    padding: 0 $pR $pB $pL;
  }
  @media (max-width: 1920px) {
    $pR: calc(10px + (50 - 10) * ((100vw - 720px) / 1200));
    $pB: calc(10px + (30 - 10) * ((100vw - 720px) / 1200));
    $pL: calc(20px + (105 - 20) * ((100vw - 720px) / 1200));
    padding: 0 $pR $pB $pL;
  }
}
