@import '../../styles/initstyles.scss';

.main-page {
  margin: 0 auto;
}

.services {
  position: relative;
}

.white {
  display: none;
  @media (min-width: 773px) {
    position: absolute;
    display: block;
    background-color: $white;
    height: 643px;
    width: 10px;
    top: 0;
    left: -9px;
  }
  @media (min-width: 1004px) {
    left: -8px;
  }
}

.desktop-slider {
  display: flex;
  @media (min-device-width: 1440px) and (max-device-width: 1599px) {
    padding-left: 50px;
  }
  @media (min-width: 1200px) {
    padding-top: 160px;
  }
  @media (max-width: 1199px) {
    padding-top: 125px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.main-page .reasons {
  @media (min-device-width: 2559px) {
    padding-right: 0;
    margin-top: 60px;
  }
  @media (min-device-width: 1920px) {
    padding-top: 103px;
    padding-right: 200px;
  }
  @media (min-device-width: 1200px) {
    display: flex;
    text-align: justify;
    padding-bottom: 50px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.green {
  display: none;
  @media (min-width: 1153px) {
    background-color: $darkGreen;
    position: absolute;
    display: block;
    overflow: hidden;
    width: 2%;
    height: 643px;
    left: 1117px;
    top: 0;
  }
  @media (min-width: 1192px) and (max-width: 1250px) {
    width: 43px;
  }
  @media (min-width: 1250px) and (max-width: 1350px) {
    width: 69px;
  }
  @media (min-width: 1350px) {
    width: 120px;
  }
  @media (min-width: 1440px) {
    width: 150px;
  }
  @media (min-width: 1600px) {
    width: 240px;
  }
  @media (min-width: 1920px) {
    width: 400px;
  }
  @media (min-width: 2560px) {
    width: 729px;
  }
}

.videoAutoPlay {
  overflow: hidden;
}

.point-number {
  display: none;
}

.services-button {
  font-size: 14px;
  @include buttonActions;
}

@media (max-width: 768px) {
  .services-header,
  .item-number,
  .videosection,
  .services-button {
    display: none;
  }

  .services {
    padding-top: 32px;
    margin: 0 auto;
    .mobile-slider {
      @include flexCol;
      align-items: center;
      padding: 0 20px;
      .slick-slider {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        .slick-list {
          .slider-item {
            @include flexCol;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .cube1 {
    background-image: url(../../styles/cube1.png);
    position: absolute;
    top: 55px;
    margin-left: 50%;
    width: 27px;
    height: 26px;
  }

  .cube2 {
    background-image: url(../../styles/cube2.png);
    position: absolute;
    top: 285px;
    margin-left: 60%;
    width: 30px;
    height: 31px;
  }

  .slick-dots {
    position: static;
    display: flex !important;
    padding-top: 54px;
    padding-bottom: 72px;
    li {
      @include rMedium16;
      padding-right: 30px;
    }
    .slick-active {
      color: $salatGreen;
      @include rMedium18;
    }
  }

  .slider-header {
    @include uRegular24;
    padding: 0;
  }

  .slider-text {
    @include uRegular14;
    padding-top: 16px;
  }

  .slider-button {
    width: 100%;
    text-align: center;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
    padding: 13px 70px;
    background: $darkGreen;
    color: $lightYellow;
    &:active {
      background: $green;
    }
  }

  .main-page .reasons .reasons-header {
    display: flex;
    align-items: center;
    padding: 35px 0 5px;
  }

  .reasons-header > p:nth-child(2) {
    @include uMedium18;
  }

  .reasons-quantity {
    @include uBold;
    font-size: 38px;
    color: $darkGreen;
    padding-right: 10px;
    padding-bottom: 12px;
  }

  .reasons-points {
    p {
      @include uRegular14;
      padding-bottom: 16px;
    }
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .main-page {
    overflow: hidden;
  }

  .videosection {
    height: 100%;
    right: 0;
    position: absolute;
    z-index: -2;
  }

  .slider-numbers {
    height: 292px;
    padding-right: 49px;
    .item-number-active,
    .item-number {
      padding-top: 15px;
      padding-bottom: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .item {
    height: 300px;
    .service-header {
      @include uRegular30;
      font-weight: 600;
      width: 300px;
      padding-bottom: 46px;
    }
    p {
      @include uRegular16;
      padding-bottom: 123px;
      width: 340px;
      line-height: 150%;
    }
  }

  .services .item-number {
    @include rMedium18;
  }

  .item-number-active {
    @include rMedium40;
  }

  .services-button {
    background: $darkGreen;
    color: $lightYellow;
    width: 214px;
    height: 52px;
    margin-left: 90px;
    line-height: 360%;
    text-align: center;
    &:hover {
      background: $green;
    }
  }

  .services {
    position: relative;
    overflow: hidden;
    padding-left: 12px;
    .mobile-slider,
    .cube1,
    .cube2 {
      display: none;
    }
  }

  .reasons {
    display: flex;
    margin-top: 100px;
    padding: 0 12px 75px;
  }

  .reasons-header {
    display: flex;
  }

  .reasons-header p:nth-child(2) {
    @include uMedium18;
    width: 100px;
    padding-top: 50px;
    margin-right: 100px;
  }

  .reasons-quantity {
    padding-right: 10px;
    @include uBold144;
    color: $darkGreen;
    line-height: 120%;
  }

  .reasons-points {
    margin-top: 15px;
    max-width: 550px;
    p {
      @include uRegular16;
      padding-bottom: 16px;
    }
  }
}

@media (min-device-width: 1076px) and (max-device-width: 1199px) {
  .main-page {
    overflow: hidden;
  }

  .services {
    height: 570px;
  }
}

@media (min-device-width: 1200px) {
  .services {
    height: 670px;
  }

  .services {
    position: relative;
    max-width: 1143px;
  }

  .videosection {
    position: absolute;
    z-index: -2;
  }

  .mobile-slider,
  .cube1,
  .cube2 {
    display: none;
  }

  .slider-numbers {
    height: 292px;
    padding-right: 69px;
    .item-number-active,
    .item-number {
      padding-top: 15px;
      padding-bottom: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .item {
    height: 300px;
    .service-header {
      @include uRegular;
      font-size: 40px;
      font-weight: 600;
      width: 400px;
      padding-bottom: 26px;
    }
    p {
      @include uRegular16;
      padding-bottom: 123px;
      width: 320px;
      line-height: 150%;
    }
  }

  .services .item-number {
    @include uRegular;
    font-size: 20px;
  }

  .item-number-active {
    @include rMedium40;
  }

  .services-button {
    background: $darkGreen;
    color: $lightYellow;
    @include uRegular16;
    padding-top: 15px;
    &:hover {
      background: $green;
    }
    width: 214px;
    height: 52px;
    margin-left: 116px;
    text-align: center;
  }

  .reasons-header {
    display: flex;
  }

  .reasons-header p:nth-child(2) {
    @include uMedium24;
    width: 178px;
    padding-top: 100px;
    margin-right: 154px;
  }

  .reasons-quantity {
    padding-right: 20px;
    font-family: 'UbuntuRegular', serif;
    font-size: 124px;
    line-height: 180%;
    color: #7a932c;
  }

  .main-page .reasons-points {
    margin-top: 85px;
  }

  .point-text {
    @include uRegular18;
    max-width: 492px;
    padding-bottom: 25px;
  }
}

@media (min-device-width: 1400px) {
  .services {
    height: 595px;
  }
}

@media (min-device-width: 1440px) and (max-device-width: 1599px) {
  .services-button {
    margin-left: 170px;
  }

  .banks-info {
    margin-left: 4vw;
  }

  .footer-logo {
    margin-right: 150px;
  }
}

@media (min-device-width: 1460px) {
  .videosection {
    max-width: 100%;
  }

  .services {
    height: 650px;
  }
}

@media (device-width: 1920px) {
  .item {
    p {
      width: 440px;
    }
  }

  .main-page .banks {
    .banks-info {
    }
  }

  .videosection {
    max-width: 1143px;
  }

  .services-button {
    margin-left: 116px;
  }
}

@media (min-device-width: 2559px) {
  .main-page .reasons .reasons-header {
    padding-right: 200px;
  }
}
