@import '../../styles/initstyles.scss';

.banks {
  @include flexCol;
  @media (max-device-width: 768px) {
    margin-bottom: 30px;
  }
}

.banks-info {
  display: flex;
  justify-content: space-between;
}

.banks-header {
  @include textLeft;
  font-weight: bold;
}

.banks-header:first-child {
  background: $green;
}

.banks-navigate p {
  @include textLeft;
}

.banks-slider {
  @media (min-device-width: 1200px) {
    @include flexRow;
    flex-wrap: wrap;
    min-width: 700px;
    height: 270px;
    margin-left: 40px;
  }
  @media (max-device-width: 1199px) {
    @include flexRow;
    flex-wrap: wrap;
    min-width: 600px;
    max-width: 98%;
    height: 270px;
    margin-left: 60px;
  }
  @media (max-device-width: 1133px) {
    margin-left: 20px;
  }
  @media (max-device-width: 1070px) {
    margin-left: 0;
  }
  @media (max-device-width: 768px) {
    @include flexRow;
    flex-wrap: wrap;
  }
  @media (max-device-width: 686px) {
    height: auto;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.bank-item {
  @media (min-device-width: 1200px) {
    width: 200px;
    height: 58px;
  }
  @media (max-device-width: 1199px) {
    width: 200px;
    height: 58px;
    margin: 0 20px 30px;
  }
  @media (max-device-width: 797px) {
    width: 200px;
    height: 58px;
    margin: 0 12px 40px;
  }
  @media (max-device-width: 727px) {
    margin: 0 2px 15px;
  }
  @media (max-device-width: 686px) {
    margin: 0 20px 30px;
  }
  @media (max-device-width: 320px) {
    margin: 0 15px;
  }
}

.bank-item:nth-child(3n + 1) {
  @media (min-device-width: 1200px) {
    margin: 0 0 48px 0;
  }
  @media (max-device-width: 1199px) {
    margin: 0 0 30px;
  }
  @media (max-device-width: 1024px) {
    margin: 0 10px 30px;
  }
  @media (max-device-width: 686px) {
    margin: 0 20px 30px;
  }
  @media (max-device-width: 320px) {
    margin: 0 15px 15px;
  }
}

.bank-item:nth-child(3n - 1) {
  @media (min-device-width: 1200px) {
    margin: 0 0 48px 42px;
  }
  @media (max-device-width: 320px) {
    margin: 0 15px 15px;
  }
}

.bank-item:nth-child(3n) {
  @media (min-device-width: 1200px) {
    margin: 0 0 48px 42px;
  }
  @media (max-device-width: 320px) {
    margin: 0 15px 15px;
  }
}

@media (max-device-width: 768px) {
  .banks-header {
    @include uRegular18;
    @include fAlignCenter;
  }

  .banks-header:first-child {
    width: 100%;
    height: 47px;
    padding-left: 19px;
    margin-bottom: 10px;
  }

  .banks-header p {
    padding-right: 7px;
  }

  .banks-navigate {
    @include flexCol;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .banks-navigate p {
    padding-bottom: 32px;
    font-size: 14px;
    @include uRegular14;
  }

  .banks-info {
    overflow: hidden;
    flex-direction: column;
    margin-top: 24px;
    padding: 0px 20px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .banks {
    padding-bottom: 45px;
  }

  .banks-header {
    @include uRegular24;
  }

  .banks-header:first-child {
    width: 404px;
    height: 62px;
    padding-top: 25px;
  }

  .banks-header p {
    margin-left: 20%;
  }

  .banks-navigate {
    @include flexCol;
    align-items: flex-start;
    min-width: 290px;
    padding: 0 50px 50px 0;
  }

  .banks-navigate p {
    width: 100%;
    text-align: justify;
    max-width: 303px;
    @include uRegular18;
  }

  .banks-info {
    margin: 100px 0 0 80px;
  }
}

@media (min-device-width: 1200px) {
  .banks {
    padding-bottom: 75px;
    width: 1440px;
  }

  .banks-header {
    @include uRegular24;
  }

  .banks-header:first-child {
    width: 404px;
    height: 62px;
    padding-top: 25px;
  }

  .banks-header p {
    margin-left: 86px;
  }

  .banks-navigate {
    @include flexCol;
    align-items: flex-start;
  }

  .banks-navigate p {
    min-width: 300px;
    @include uRegular18;
  }

  .banks-info {
    margin: 80px 0 0 0;
    padding-left: 86px;
  }
}

@media (max-device-width: 1024px) {
  .banks-info {
    flex-direction: column;
  }
  .banks-navigate {
    max-width: 100%;
    > p {
      max-width: 100%;
    }
  }
}

@media (max-device-width: 320px) {
  .banks-navigate {
    > p {
      text-align: justify;
    }
  }
}
