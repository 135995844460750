@import '../../styles/initstyles.scss';

.hdm-menu {
  height: 36px;
  background-color: $yGreen;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;

  @media (min-device-width: 1024px) {
    font-size: 13px;
    padding: 0 10px;
  }
}

.white {
  position: absolute;
  width: 8px;
  height: 624px;
  z-index: 10;
  left: -6px;
  background: white;
}

.services-menu {
  display: none;
}

.hdm-menu > a:nth-child(2) { 
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
}

.popup-content {
  display: inline-table;
  background-color: #f0f9d4;

  @media (min-device-width: 1600px) {
    max-width: 640px;
  }

  @media (min-device-width: 1920px) {
    max-width: 480px;
  }
}

.hdm-lk a.menu-item:nth-child(2) {
  margin: 0 40px;
  width: 83px;
}

@media (max-device-width: 768px) {
  .header-desktop-menu {
    display: none;
  }
}

@media (min-device-width: 769px) {
  .header-desktop-menu {
    position: sticky;
    background-color: $yGreen;
    left: 0;
    right: 0;
    top: 80px;
    z-index: 12;
  }

  .hdm-lk {
  }

  .dm-control {
    border: none;
    width: 212px;
    background: $yGreen;
    color: $black;
    height: inherit;
    &:hover {
      color: $lightYellow;
    }
  }

  .menu-item {
    padding: 13px 13px 13px 13px;
    @include uRegular14;
    color: $black;
    &:hover {
      color: #CCE271;
    }
  }

  .menu-item-active {
    @include uRegular14;
    padding-right: 18px;
    color: $white;
  }

  .hdm-lk > .menu-item-active:nth-child(2) {
    margin: 0px 40px 0px 40px;
    width: 83px;
    @include uRegular14;
    color: $white;
  }

  .bm-section {
    z-index: 1;
    color: $black;
    display: flex;
    @include uRegular14;
    svg {
      margin-left: 7px;
      margin-top: 6px;
      path {
        fill: #B5C15D;
      }
    }
    &:hover {
      color: $white;
      svg {
        stroke: $white;
        path {
          fill: $white;
        }
      }
    }
  }

  .bm-section-active {
    color: $white;
    display: flex;
    @include uRegular14;
    svg {
      margin-left: 7px;
      margin-top: 6px;
      stroke: $white;
      path {
        fill: $white;
      }
    }
  }

  .dm-menu {
    width: 210px;
    height: 212px;
    overflow-y: visible;
    padding: 13px 0px 13px 0px;
    border: none;
  }

  .dm-option {
    width: 210px;
    padding: 13px 54px 13px 24px;
    color: $black;
    background: $white;
    &:hover {
      background: $green;
    }
  }

  .d-menu {
    @include flexCol;
    background-color: $white;
    .d-item {
      padding: 13px 18px 13px 24px;
      @include uRegular14;
      &:hover {
        color: #CCE271;
      }
    }
  }

  .d-item {
    padding: 13px 13px 13px 13px;
    &:hover {
      color: #CCE271;
    }
  }

  .d-item-active {
    padding: 13px 18px 13px 24px;
    @include uRegular14;
    color: $white;
  }

  .popup-content .d-menu {
    padding: 30px 20px 30px 0;
    border-radius: 2px;
    width: 212px;
    z-index: 1;
    box-shadow: 0 0 3px rgba(0,0,0,.16);
  }
}
