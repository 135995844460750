@import "src/styles/initstyles";

.not-found-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found__container {
  padding: 32px 0;
  text-align: center;
  margin: 0 auto;
  max-width: 570px;
  min-width: 570px;
  @media (max-width: 768px) {
    padding: 32px 20px;
    margin: 0;
    text-align: inherit;
    max-width: 100%;
    min-width: 0;
  }
}

.not-found__title {
  @include uBold;
  color: $black;
  font-size: 40px;
  line-height: 120%;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.not-found__code {
  @include uBold;
  color: $darkGreen;
  font-size: 160px;
  @media (max-width: 768px) {
    font-size: 72px;
  }
}

.not-found__subtitle {
  @include uBold;
  color: $black;
  font-size: 30px;
  line-height: 120%;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.not-found__text {
  @font-face;
  color: #767774;
  font-size: 18px;
  line-height: 180%;
  padding-bottom: 8px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 160%;
  }
}

.not-found__button-container {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    button {
      margin: 8px 0;
    }
  }
}
