@import '../../styles/initstyles.scss';

a {
  color: inherit;
  outline: none;
}

.header {
  position: sticky;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
}

.logo {
  @media (max-device-width: 480px) {
    position: absolute;
    top: 10px;
  }
}

.mailTo {
  padding-right: 10px;
}

.phone {
  @media (min-device-width: 1200px) {
    font-size: 24px;
    padding-right: 48px;
  }
  @media (max-device-width: 1199px) {
    font-size: 24px;
    padding-right: 48px;
  }
  @media (max-device-width: 849px) {
    padding-right: 6px;
  }
}

.mob-phone {
  @media (max-device-width: 768px) {
    @include uMedium16;
    margin-right: 48px;
    position: absolute;
    top: 15px;
    right: 35%;
  }
  @media (max-device-width: 480px) {
    margin-right: 22%;
    top: 17px;
    right: 13%;
  }
  @media (max-device-width: 320px) {
    margin-right: 14%;
    top: 17px;
    right: 13%;
  }
}

.phone div {
  display: flex;
  padding-right: 10px;
}

.phone-component {
  display: flex;
  a {
    margin-top: 1px;
  }
}

.contacts-logos,
.header-contacts {
  @include flexRow;
  max-width: 1440px;
  margin: 0 auto;
}

@media (max-device-width: 768px) {
  .header {
    position: sticky;
    height: 52px;
    padding: 0 19px;
    justify-content: space-between;
    z-index: 14;
    @include linearGradientLeft;
    @include bottomShadow;
  }

  .header-contacts {
    padding-top: 10px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .logo {
    padding-bottom: 11px;
  }

  .hc-ph-soc,
  .h-contacts {
    display: none;
    cursor: pointer;
  }

  .mob-phone {
    .phone-component {
      svg {
        margin-right: 0px;
        width: 16px;
        height: 16px;
      }
    }

    .email-component {
      display: flex;
      img {
        width: 24px;
        height: 24px;
        margin-right: 14px;
      }
    }
  }

  .email-header {
    top: 15px;
    right: 5%;
    @media (max-device-width: 600px) {
      display: none;
    }
  }

  .burger-menu {
    position: absolute;
    top: 15px;
    right: 25px;
    @include flexCol;
    width: 24px;
    height: 24px;
  }

  .b-menu {
    @include flexCol;
    border-radius: 3px;
    height: 309px;
    padding: 22px 0px 37px 0px;
    background-color: $white;
    justify-content: space-between;
    .bm-item {
      outline: none;
      padding-left: 33px;
      @include uRegular14;
    }
    .bm-section {
      @include uRegular16;
      padding-left: 20px;
    }
  }

  .popup-content .b-menu {
    border-radius: 3px;
    width: 246px;
    left: 76px !important;
    box-shadow: 0 0 3px rgba(0,0,0,.16);
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .header {
    @include linearGradientRight;
    height: 80px;
    padding: 0 15px;
    justify-content: space-around;
    z-index: 10;
  }

  .header-contacts {
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    .hc-ph-soc {
      display: flex;
    }
  }

  .logo {
    margin-top: 8px;
  }

  .mob-phone,
  .burger-menu {
    display: none;
  }

  .phone svg {
    margin-top: 3px;
  }

  .contacts-logos {
    margin: 4px 0 0 30px;
    width: 156px;
    justify-content: space-between;
  }
}

@media (max-device-width: 1024px) {
  .header-contacts {
    max-width: 100%;
  }
}

@media (min-device-width: 1200px) {
  .header {
    z-index: 10;
    @include linearGradientRight;
    height: 80px;
    .hc-ph-soc {
      display: flex;
    }
  }

  .header-contacts {
    max-width: 1201px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    margin-top: 8px;
  }

  .mob-phone,
  .burger-menu {
    display: none;
  }

  .phone svg {
    padding-top: 3px;
  }

  .contacts-logos {
    margin: 5px 0px 0px 30px;
    width: 156px;
    justify-content: space-between;
  }
}
