@import "src/styles/initstyles";

.button {
  @include buttonActions;
  width: 270px;
  height: 52px;
  @include uRegular16;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
  @media (max-width: 320px) {
    width: 280px;
  }
}
