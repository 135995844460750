@import '../../../styles/initstyles.scss';

.table-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 100px 0;
  @media (max-device-width: 768px) {
    margin: 20px 0 60px;
    min-width: 100%;
  }
  @media (max-device-width: 480px) {
    max-width: 480px;
    margin: 0;
  }
  @media (max-device-width: 320px) {
    max-width: 320px;
  }
}

.table-title {
  height: 49px;
  display: flex;
  min-width: 75%;
  margin-bottom: 20px;

  > div:nth-child(3) {
    @media (max-device-width: 480px) {
      display: none;
    }
  }
}

.table-nothing-badge {
  padding: 0 0 0 82px;
  @media (max-width: 768px) {
    padding-left: 18px;
  }
}

.table-title-cell {
  min-width: 22%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-device-width: 480px) {
    min-width: 56%
  }
}

.table-title-cell-bank {
  min-width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7A932C;
  @media (max-device-width: 768px) {
    min-width: 23%
  }
  @media (max-device-width: 480px) {
    min-width: 50%
  }
}

.params {
  background-color: #B5C15D;
  @media (max-device-width: 768px) {
    min-width: 25%;
  }
  @media (max-device-width: 480px) {
    min-width: 50%;
  }
}

.title-span {
  font-family: Ubuntu;
  color: $lightYellow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
}

.cost {
  padding-left: 10%;
}

.title-button {
  position: relative;
  color: $lightYellow;
  &:after {
    position: absolute;
    right: -14px;
    top: 3px;
    content: '';
    display: block;
    width: 8px;
    height: 14px;
    background-image: url(../../../styles/filter-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
}
