@import '../../styles/initstyles.scss';
@import '../../styles/checkboxes.scss';

#root {
  height: 100%;
}

.App {
  @include flexCol;
  justify-content: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 100%;
  z-index: 2;
}

@font-face {
  font-family: 'Ubunturegular';
  src: url(../../fonts/Ubunturegular.ttf);
}

.info-block {
  @media (max-device-width: 768px) {
    min-width: 0;
  }
}

input,
textarea {
  font-family: 'Ubunturegular', serif;
  font-size: 14px;
  outline: none;
  padding-left: 15px;
}

textarea {
  padding-top: 16px;
  padding-left: 16px;
}

input[type=checkbox] {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100%;
}

p, h1, h2, h3, ul, li {
  margin: 0;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 2px;
  outline: none;
  &:enabled {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.info-text {
  @include textLeft;
  font-size: 16px;
  line-height: 22.4px;
  @media (min-device-width: 1200px) {
    padding-left: 30px;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    padding-top: 15px;
  }
}

.info-text p {
  text-align: justify;
  @media (min-device-width: 1200px) {
    padding-bottom: 35px;
    @include uRegular18;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 28.8px;
    @include uRegular14;
    text-align: justify;
  }
  @media (max-device-width: 1024px) {
    text-align: justify;
  }
  @media (max-device-width: 768px) {
    @include uRegular14;
    padding-bottom: 16px;
  }
}

@media (max-device-width: 768px) {
  .info-pic {
    display: none;
  }

  .monitoring-services {
    margin-bottom: 120px;
  }

  .info-header {
    margin-top: 8px;
    padding: 13px 84px 13px 20px;
    background: $green;
    h2 {
      @include uRegular18;
    }
  }

  .info-block {
    display: flex;
    padding: 16px 20px 20px 20px;
    max-width: 100%;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .info-block {
    margin-bottom: 40px;
    display: flex;
  }

  .info-header {
    display: none;
  }

  .info-pic img {
    padding-right: 30px;
  }

  .bank-services {
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .monitoring-services {
    justify-content: center;
    align-items: center;
    margin: 80px 0 120px
  }
}

@media (min-device-width: 1200px) {
  .info-block {
    display: flex;
    margin-bottom: 64px;
    @include maxWidth;
  }

  .info-header {
    display: none;
  }

  .bank-services,
  .monitoring-services {
    justify-content: center;
    align-items: center;
  }

  .bank-services,
  .trade-services,
  .monitoring-services { 
    width: 100%;
    max-width: 1143px;
  }
}

@media (max-device-width: 320px) {
  .monitoring-services {
    margin-bottom: 90px;
  }
}
