@import '../../styles/initstyles.scss';

.policy {
  @include flexCol;
  justify-content: center;
}

.header-container {
  margin-bottom: 30px;
}

.policy-container--header {
  @include textLeft;
  .first-string {
    padding-top: 5px;
  }
}

.policy-container--header p {
  padding-left: 80px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.policy-container {
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
  padding: 30px 15px 100px 80px;
  @media (device-width: 1920px) {
    margin: 0 0 80px;
  }
  @media (max-device-width: 768px) {
    font-size: 14px;
    line-height: 21px;
    padding-left: 20px;
    max-width: 100%;
  }
}

.policy-container--header:first-child {
  background: $green;
}

.policy-title {
  font-weight: 500;
}

.policy-block {
  padding: 0 0 20px;
}

.title-container {
  padding: 0 0 10px;
}

.policy-container--header:first-child {
  width: 435px;
  height: 62px;
  padding-top: 30px;
  @media (max-device-width: 768px) {
    width: 100%;
  }
}

.police-text {
  > p {
    padding: 5px 0;
  }
}

@media (max-device-width: 768px) {
  .policy-container--header {
    height: 47px;
    padding-left: 20px;
    margin-bottom: 33px;
    justify-content: center;
    @include flexCol;
    p {
      @include textLeft;
      @include uMedium18;
      padding-right: 7px;
    }
  }

  .header-container {
    margin-bottom: 0;
  }

  .policy-container--header {
    @include textLeft;
    .first-string {
      padding-top: 0;
      margin-left: 0;
    }
    .second-string {
      margin-left: 0;
    }
  }

  .policy-container--header:first-child {
    padding-top: 13px;
  }

  .policy-container--header > p:nth-child(1) {
    margin-top: 44px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .policy-container--header {
    @include uMedium24;
  }

  .policy-container--header:first-child {
    width: 404px;
    height: 62px;
    padding-top: 25px;
  }
}

@media (min-device-width: 1200px) {
  .policy-container--header {
    margin-bottom: 33px;
    p {
      @include uMedium24;
      padding-left: 18%;
      padding-bottom: 7px;
    }
  }
}
