@import '../../styles/initstyles.scss';

.App .dm-path {
  justify-content: flex-start;
  align-self: center;
  @media (min-device-width: 1024px) {
    padding-left: 12px;
  }
  @media (max-device-width: 1024px) {
    .dm-path {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

@media (max-device-width: 768px) {
  .dm-path {
    display: none;
  }
}

@media (min-device-width: 769px) {
  .dm-path {
    @include uRegular16;
    @include textLeft;
    @include fAlignCenter;
    padding: 32px 0 44px;
    svg {
      margin: 3px 12px 0;
    }
    .place {
      color: $green;
    }
  }

  .path-general {
    color: $black;
    &:hover {
      color: $green;
    }
  }

  .path-current {
    color: $green;
  }
}

@media (min-device-width: 1440px) {
  .dm-path {
  }
}
