@import '../../styles/initstyles.scss';

.about,
.info .reasons-points {
  @include flexCol;
}

.about-info {
  display: flex;
  justify-content: space-between;
  @media (max-device-width: 768px) {
    padding: 0 20px;
  }
  @media (max-device-width: 480px) {
    padding: 0;
  }
}

.point-text {
  @include textLeft;
  text-align: justify;
}

.contacts-page {
  overflow-x: hidden;
  max-width: 1143px;
  margin: 0 auto;

  @media (max-device-width: 768px) {
    max-width: 100%;
  }
}

.map {
  @media (min-device-width: 1930px) {
    .ymaps-2-1-78-map {
      width: 100%;
      max-width: 1201px;
    }
  }
  @media (min-device-width: 1440px) {
    .ymaps-2-1-78-map {
      width: 100%;
      max-width: 1201px;
    }
  }
  @media (min-device-width: 1200px) {
    padding: 35px 0 80px;
    .ymaps-2-1-78-map {
      width: 100% - 10%;
      max-width: 1201px - 118px;
    }
  }
  @media (min-device-width: 1024px) {
    padding: 35px 0 80px;
  }
  @media (max-device-width: 320px) {
    padding: 0 0 10px;
    .ymaps-2-1-78-map {
    }
  }
}

.company-info {
  display: flex;
  justify-content: space-between;
  padding: 0 20% 0 0;
  @media (max-device-width: 1024px) {
    padding: 0 0 40px;
  }
  @media (max-device-width: 768px) {
    flex-direction: column;
    padding: 32px 20px;
  }
  @media (max-device-width: 480px) {
    padding: 20px 0 30px;
  }
  @media (max-device-width: 320px) {
    padding: 0 0 30px;
  }
}

.company-info div {
  @media (max-device-width: 768px) {
    padding: 10px 0;
  }
}

.contact-item {
  font-size: 18px;
  line-height: 20.68px;
  padding-bottom: 15px;
}

.contacts-header {
  padding-top: 70px;
  font-size: 24px;
  line-height: 27.58px;
  font-weight: 500;
  @media (max-device-width: 768px) {
    padding-top: 30px;
  }
}

@mixin contacts-block {
  flex-direction: column;
  font-size: 24px;
  line-height: 27.58px / 24px * 100%;
}

.number-contacts {
  @include contacts-block;
  font-weight: 500;
}

.number-contacts {
  div:first-child {
    padding: 0 5px 10px 0;
    @media (max-device-width: 480px) {
      padding: 0 5px 5px 0;
    }
  }
  div a {
    margin-top: 5px;
  }
}

.address-contacts {
  @include contacts-block;
  p {
    padding: 7px 0 14px;
    @media (max-device-width: 480px) {
      padding: 8px 0;
    }
  }
}

.contacts-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-device-width: 1330px) {
    padding: 0 10px;
    margin-left: 0;
    align-self: center;
  }
  @media (min-device-width: 1200px) {
    flex-wrap: wrap;
  }
  @media (max-device-width: 1199px) {
    @include flexCol;
    align-self: center;
  }
  @media (min-device-width: 1024px) {
    padding: 0 12px;
  }
  @media (max-device-width: 768px) {
    flex-direction: column;
  }
  @media (max-device-width: 480px) {
    padding: 0 20px;
  }
}

.left-part {
  max-width: 50%;
  @media (max-device-width: 768px) {
    max-width: 100%;
  }
}

.right-part {
  max-width: 50%;
  @media (max-device-width: 768px) {
    display: none;
  }
}

.about-image-wrapper {
  width: 382px;
  height: 487px;
}

.about-text {
  flex-direction: column;
  font-size: 18px;
  line-height: 28.8px;
  @media (max-device-width: 480px) {
    font-size: 16px;
    line-height: 22.4px;
  }
}

.about-text p {
  padding-bottom: 20px;
  text-align: justify;
}

.map-info {
  width: 300px;
  height: 133px;
  border-radius: 2px;
  padding: 9px 32px 0px 69px;
  background-color: $lightYellow;
}

.map-header {
  @include uRegular14;
}

.map-text {
  @include uRegular12;
}

.company-contacts {
  display: flex;
  margin-bottom: 10px;
}

.company-name {
  @media (min-device-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 85px 40px 0;
  }
  @media (max-device-width: 1199px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 85px 50px 0;
    @include uMedium24;
  }
  @media (max-device-width: 768px) {
    display: flex;
    padding: 0;
    margin: 30px 0;
  }
  @media (max-device-width: 480px) {
    margin: 0;
  }
}

.company-name > div:nth-child(1) {
  @media (min-device-width: 1200px) {
    @include uMedium24;
  }
  @media (max-device-width: 768px) {
    width: 100%;
    height: 74px;
    @include uMedium18;
    display: flex;
    align-items: center;
    @include linearGradientLeft;
    background: none;
    justify-content: flex-start;
    font-size: 24px;
  }
  @media (max-device-width: 480px) {
    font-size: 20px;
    background: none;
  }
  @media (max-device-width: 320px) {
    font-size: 16px;
  }
}

.company-name .phone-component {
  @media (min-device-width: 1200px) {
    padding-top: 40px;
    a {
      @include rMedium;
      font-size: 30px;
      padding-left: 22px;
    }
    svg {
      margin-top: 10px;
    }
  }
  @media (max-device-width: 1199px) {
    padding-top: 40px;
    align-items: center;
    a {
      @include rMedium;
      font-size: 30px;
      padding-left: 17px;
    }
    svg {
      margin-top: 10px;
    }
  }
  @media (max-device-width: 768px) {
    display: flex;
    align-self: flex-start;
    position: absolute;
    top: 15px;
    right: 45%;
    padding-left: 6%;
    padding-top: 25px;
    a {
      @include uRegular16;
      font-weight: normal;
      padding-left: 7px;
    }
    svg {
      width: 16px;
    }
  }
}

@media (max-device-width: 768px) {
  .company-contacts {
    flex-direction: column;
  }

  .right-part {
    margin-top: 30px;
  }

  .company-contacts {
    display: flex;
    padding-top: 23px;
    .worktime {
      padding: 23px 0px;
    }
  }

  .info {
    display: flex;
    .reasons {
      margin-top: 8px;
    }
    .point-number,
    img {
        display: none;
      }
  }

  .info .reasons-header {
    @include fAlignCenter;
    @include uRegular18;
    background: $green;
    padding-left: 19px;
    height: 47px;
    p {
      padding-right: 7px;
    }
  }

  .info .reasons-header > p:nth-child(2) {
    padding-bottom: 4px;
  }

  .info .reasons-points {
    @include uRegular14;
    padding: 16px 30px 60px 20px;
    .point-text {
      padding-bottom: 16px;
      @include uRegular14;
    }
  }

  .info .point-number,
  .info img {
    display: none;
  }

  .about .banks {
    margin-bottom: 30px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .contacts-page .contact-us,
  .contacts-page .dm-path {
    align-self: center;
  }

  .company-contacts {
    @include uRegular18;
    .email {
      a {
        padding-left: 8px;
      }
      svg {
        margin-top: 4px;
      }
    }
  }

  .about {
    align-items: center;
  }

  .about-img {
    width: 50.1%;
    max-width: 501px;
    height: 67.6%;
    max-height: 617px;
  }

  .info {
    display: flex;
    padding-bottom: 87px;
    .reasons {
      margin-top: 12px;
      margin-left: 93px;
      .reasons-points {
        height: 564.34px;
        max-width: 548px;
        justify-content: space-between;
      }
    }
  }

  .info .reasons-header,
  .info .point-number {
    display: none;
  }

  .point-text {
    @include uRegular18;
  }
}

@media (min-device-width: 1200px) {
  .about .info,
  .about .banks {
    align-self: center;
  }

  .info {
    display: flex;
    padding-bottom: 87px;
    .reasons {
      margin-top: 25px;
      margin-left: 93px;
      .reasons-points {
        height: 564.34px;
        max-width: 548px;
        justify-content: space-between;
      }
    }
  }

  .info .reasons-header,
  .info .point-number {
    display: none;
  }

  .point-text {
    @include uRegular18;
  }

  .company-contacts {
    .worktime {
      @include uRegular16;
      line-height: 200%;
      padding: 0 140px;
    }
    .address {
      @include uRegular16;
      line-height: 200%;
    }
    .email {
      @include uRegular16;
      line-height: 200%;
      svg {
        margin-top: 5px;
      }
    }
  }
}

@media (min-device-width: 1930px) {
  .App .contacts-page .contact-us {
    padding: 0 10px;
    margin-left: 0;
    align-self: center;
  }
}

@media (max-device-width: 320px) {
  .contacts-header {
    padding: 25px 0;
    font-size: 20px;
    line-height: 22.98px;
    font-weight: 500;
  }

  .company-contacts {
    padding-top: 0;
    .worktime {
      padding: 0;
    }
  }
}
