.private-section {}

.private-container {
  padding: 0 12px;
  margin: 0 0 60px 0;
  @media (max-width: 768px) {
    margin: 30px 0 30px 0;
  }
}

.private-title {
  font-size: 24px;
  line-height: (27.6 / 24) * 100%;
  font-weight: 500;
  padding-bottom: 32px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.private-subtitle {
  font-size: 18px;
  line-height: (28.8 / 18) * 100%;
  font-weight: 500;
  padding-bottom: 32px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.private-list-title {
  font-size: 18px;
  line-height: (25.5 / 18) * 100%;
  font-weight: 400;
  padding-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.private-list {
  font-size: 18px;
  line-height: (28.8 / 18) * 100%;
  font-weight: 400;
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 40px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 0 0 20px;
  }
  li {
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 0 0 16px 0;
    text-align: justify;
    @media (max-width: 768px) {
      padding: 0 0 10px 0;
    }
    &:before {
      position: absolute;
      content: "";
      top: 20px;
      left: -39px;
      height: 24px;
      width: 24px;
      background-image: url("../../styles/marker.svg");
      background-repeat: no-repeat;
      @media (max-width: 768px) {
        height: 8px;
        width: 8px;
        top: 14px;
        left: -19px;
      }
    }
  }
}
