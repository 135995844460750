@import './initstyles.scss';

.price-list {
  @include fAlignCenter;
  justify-content: flex-end;
}

.pl-button {
  width: 280px;
  height: 50px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $darkGreen;
  color: $lightYellow;
  &:hover {
    background-color: $green;
  }
}

@media (max-device-width: 768px) {
  .price-list {
    @include uRegular14;
    flex-direction: column;
    font-size: 14px;
    padding: 50px 20px 50px 20px;
  }

  .pl-button {
    width: 280px;
    height: 50px;
    font-size: 16px;
  }

  .price-list span {
    padding-bottom: 16px;
    @include textLeft;
  }

}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .price-list {
    @include uRegular18;
    padding: 50px 0;
    //max-width: 800px;
    align-self: flex-end;
    .pl-button {
      @include uRegular16;
    }
  }

  .price-list span {
    font-size: 16px;
    padding-right: 36px;
  }
}

@media (min-device-width: 1200px) {
  .price-list {
    @include uRegular18;
    padding: 40px 0 75px;
    .pl-button {
      @include uRegular16;
    }
  }

  .price-list span {
    font-size: 18px;
    padding-right: 71px;
  }
}
