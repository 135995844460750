@import '../../styles/initstyles.scss';

.table-guarantees {
  @include flexCol;
}

.tbg-menu {
  color: $lightYellow;
}

.tbg-menu > div:nth-child(1) {
  height: 47px;
  background: $yGreen;
  @include fAlignCenter;
}

.tbg-menu > div:nth-child(2),
.tbg-menu > div:nth-child(3) {
  background: $green;
  @include fAlignCenter;
}

.tbg-menu,
.tbg-row {
  @include flexRow;
}

.button-zayavka {
  background: $green;
  color: $lightYellow;
  &:hover {
    background: $aYellow;
  }
}

@media (max-device-width: 768px) {
  .table-guarantees {
    margin-bottom: 49px;
  }

  .tbg-menu {
    font-size: 14px;
  }

  .tbg-menu > div:nth-child(2),
  .tbg-menu > div:nth-child(3) {
    width: 30%;
    padding-left: 100px;
    @include vhCenter;
  }

  .tbg-row {
    padding-top: 24px;
    @include vhCenter;
  }

  .tbg-image img {
    width: 106px;
    height: 32px;
  }

  .section-zayavka {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 9%;
    width: 50%;
    position: relative;
  }

  .tbg-price {
    padding: 0 9%;
    text-align: center;
    font-weight: 400;
  }

  .button-zayavka {
    width: 132px;
    height: 31px;
  }

  .tbg-rating {
    padding: 0 0 0 10%;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .table-guarantees {
    margin: 51px 0 89px 103px;
    @include flexCol;
  }

  .tbg-menu {
    padding-bottom: 16px;
    font-size: 14px;
  }

  .tbg-menu > div:nth-child(1) {
    height: 47px;
    width: 25vw;
    @include vhCenter;
  }

  .tbg-menu > div:nth-child(2),
  .tbg-menu > div:nth-child(3) {
    width: 265px;
    @include vhCenter;
  }

  .tbg-menu,
  .tbg-row {
    @include flexRow;
  }

  .tbg-row {
    margin-left: 10%;
    padding-top: 16px;
    height: 58px;
    position: relative;
  }

  .tbg-image {
    width: 16vw;
    max-width: 200px;
    height: 58px;
  }

  .tbg-rating {
    padding: 0 20% 0 13%;
  }

  .tbg-price {
    padding-right: 80px;
  }

  .section-zayavka {
    @include flexRow;
  }

  .button-zayavka {
    @include uRegular14;
    width: 171px;
    height: 38px;
  }
}

@media (min-device-width: 1200px) {
  .table-guarantees {
    @include flexCol;
    margin-bottom: 89px;
  }

  .tbg-menu {
    max-width: 865px;
    padding-bottom: 16px;
    @include uRegular14;
  }

  .tbg-menu > div:nth-child(1) {
    height: 47px;
    width: 28vw;
    max-width: 405px;
    padding-left: 27%;
  }

  .tbg-menu > div:nth-child(2),
  .tbg-menu > div:nth-child(3) {
    width: 230px;
    justify-content: center;
  }

  .tbg-menu,
  .tbg-row {
    @include flexRow;
  }

  .tbg-row {
    margin-left: 13%;
    padding-top: 16px;
    height: 58px;
  }

  .tbg-image img {
    width: 106px;
    height: 32px;

  }

  .tbg-rating {
    padding: 0px 15% 0px 17%;
  }

  .tbg-price {
    padding-right: 129px;
  }

  .section-zayavka {
    @include flexRow;
  }

  .button-zayavka {
    @include uRegular14;
    width: 171px;
    height: 38px;
  }
}

@media (max-device-width: 1024px) {
  .tbg-row {
    margin-left: 0;
  }
  .tbg-menu > div:nth-child(1) {
    width: 21vw;
  }
  .tbg-button {
    right: 15%;
    @media (max-device-width: 768px) {
      right: -34%;
      bottom: -2px;
    }
    @media (max-device-width: 320px) {
      right: 25%;
      bottom: -45px;
    }
  }
}

@media (max-device-width: 320px) {
  .tbg-price {
    display: none;
  }
  .min {
    display: none;
  }
  .tbg-menu > div:nth-child(1) {
    width: 82%;
  }
  .tbg-image {
    padding-bottom: 50px;
  }
  .tbg-rating {
    min-width: 111px;
    padding: 0 0 50px 20%;
  }
  .button-zayavka {
    width: 279px;
    height: 44px;
  }
}
