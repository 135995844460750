@import './initstyles.scss';

.company-policy {
  font-size: 14px;
  color: $lightGrey;
  @include textLeft;
  @media (device-width: 1440px) {
    max-width: 1180px;
  }
  @media (min-device-width: 1200px) {
    @include uRegular14;
    align-self: center;
    line-height: 140%;
    margin-top: 40px;
    padding: 0 0 78px 86px;
    text-align: justify;
  }
  @media (max-device-width: 1199px) {
    @include uRegular14;
    padding: 59px 0 60px 80px;
    text-align: inherit;
    min-width: 100%;
  }
  @media (max-device-width: 1024px) {
    padding: 59px 0 60px 80px;
  }
  @media (max-device-width: 768px) {
    @include uRegular12;
    padding: 0 0 100px 2%;
    margin-top: 20px;
  }
  @media (max-device-width: 480px) {
    padding: 0 20px 100px;
    margin-top: 40px;
    text-align: justify;
  }
}
