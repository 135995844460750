@import '../../styles/initstyles.scss';

.pr-name,
.pr-service {
  background: $green;
}

.pr-price {
  background: $darkGreen;
}

.prices-menu {
  color: $lightYellow;
}

.pr-price {
  width: 22%;

  @media (min-device-width: 768px) and (max-device-width: 1199px) {
    width: 27%;
  }
}

.pr-name {
  width: 22%;

  @media (min-device-width: 768px) and (max-device-width: 1199px) {
    width: 28%;
  }
}

@media (max-device-width: 768px) {
  .prices-menu {
    display: flex;
    height: 40px;
    margin: 0 0 24px 0;
  }

  .pr-price {
    @include uRegular12;
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pr-name {
    @include uRegular12;
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3%;
  }

  .pr-service {
    display: none;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .prices-menu {
    display: flex;
    height: 60px;
    margin: 0 0 30px 0;
    > div {
      padding-right: 50px;
    }
  }

  .prices-menu div {
    display: flex;
    align-items: center;
  }

  .pr-name {
    padding-left: 42px;
  }

  .pr-service {
    width: 40%;
    padding-left: 22px;
  }

  .pr-price {
    width: 30%;
    padding-left: 37px;
  }
}

@media (min-device-width: 1200px) {
  .prices-menu {
    display: flex;
    max-width: 1440px;
    height: 60px;
    margin: 0 0 30px 0;
  }

  .prices-menu div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .pr-name {
    padding-left: 14px;
    min-width: 30%;
  }

  .fin-name {
    min-width: 35%;
  }

  .pr-price {
    width: 30%;
    padding-left: 37px;
  }

  .fin-price {
    padding-left: 27px;
  }

  .right-column {
    padding-left: 20px;
  }

  .pr-service {
    width: 40%;
    padding-left: 9px;
  }

  .fin-service {
    width: 35%;
  }
}

@media (min-device-width: 1440px) {
  .pr-name {
    padding-left: 12px;
  }
}

@media (device-width: 1920px) {
  .pr-name {
    padding-left: 1%;
  }
}

@media (device-width: 1024px) {
  .pr-service {
    width: 40%;
  }
}
