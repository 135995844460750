@import './initstyles.scss';

.ts-name,
.ts-service,
.ts-price,
.mobile-price {
  @include textLeft;
}

.mobile {
  cursor: pointer;
  @media (max-width: 768px) {
    @include flexRow;
    width: 35%;
    justify-content: space-between;
    @include uRegular12;
  }
  @media (max-width: 320px) {
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      top: 40px;
      right: 13px;
    }
  }
}

@media (max-device-width: 768px) {
  .ts-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 19px 16px 19px;
    @include bottomShadow;
  }

  .ts-name {
    width: 65%;
    @include uRegular12;
  }

  .mobile-price {
    @include vhCenter;
    padding-left: 20%;
    position: relative;
  }

  .ts-service {
    display: none;
  }

  .ts-service-active {
    display: flex;
    padding: 18px 0px 24px 0px;
    @include uRegular14;
  }

  .ts-price {
    padding-left: 1px;
    display: none;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .mobile-price {
    display: none;
  }

  .trade-services {
    justify-content: center;
    align-items: center;
  }

  .ts-block {
    display: flex;
    padding-left: 30px;
    padding-bottom: 32px;
    @include uRegular14;
  }

  .ts-service {
    width: 39%;
  }

  .ts-name {
    width: 28%;
    padding: 0 6vw 0 1vw;
  }

  .ts-price {
    @include fAlignCenter;
    align-items: flex-start;
    margin-left: 1%;
    max-width: 31%;
    padding: 0 10px 0 25px;
  }
}

@media (min-device-width: 1200px) {
  .mobile-price {
    display: none;
  }

  .trade-services {
    justify-content: center;
    align-items: center;
  }

  .ts-block {
    display: flex;
    padding-bottom: 32px;
    @include uRegular14;
    line-height: 140%;
  }

  .ts-service {
    width: 450px;
  }

  .ts-name {
    width: 30%;
    padding-right: 3vw;
  }

  .ts-price {
    @include vhCenter;
    align-items: flex-start;
    max-width: 31%;
    padding-left: 3vw;
  }
}

@media (min-device-width: 1440px) {
  .ts-name {
    max-width: 30%;
  }
}

@media (min-device-width: 1920px) {
  .ts-name {
    max-width: 30%;
  }
}

@media (min-device-width: 1600px) {
  .ts-block {
    margin-left: 0;
  }
}

@media (max-device-width: 320px) {
  .ts-name {
    max-width: 50%;
  }

  .mobile-price {
    padding-left: 0;
    flex: 1 0 99%;
    min-width: 101px;
    justify-content: center;
  }
}
