@import '../../styles/initstyles.scss';

.err-notification {
  font-family: Ubuntu;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #CA3027;
  padding-top: 5px;
}

.err-policy {
  font-family: Ubuntu;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #CA3027;
}

.err-policy-input {
  border: 1px solid $redError;
}
