@import '../../styles/initstyles.scss';

.trade-header {
  @include textLeft;
}

.mobile {
  display: none;
  @media (max-device-width: 768px) {
    display: flex;
  }
  @media (max-device-width: 320px) {
    display: block;
  }
}

.trade-container {
  @media (max-device-width: 320px) {
    min-width: 0;
  }
}

.trade-header {
  &:first-child {
    background: $green;
  }
}

.trade-list {
  padding: 80px 0 80px 7%;
  @media (max-width: 1200px) {
    padding: 60px 30px 60px 82px;
  }
  @media (max-width: 768px) {
    padding: 60px 18px 60px 22px;
  }
  li {
    position: relative;
    list-style-type: none;
    @include uRegular18;
    padding: 0 0 24px 39px;
    text-align: justify;
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 0 0 10px 20px;
    }
    @media (max-width: 320px) {
      font-size: 13px;
      padding: 0 0 10px 15px;
    }
    &:before {
      position: absolute;
      content: "";
      top: 8px;
      left: 0;
      height: 24px;
      width: 24px;
      background-image: url("../../styles/marker.svg");
      background-repeat: no-repeat;
      @media (max-width: 768px) {
        height: 8px;
        width: 8px;
        top: 7px;
      }
    }
  }
}

@media (max-device-width: 768px) {
  .trade-header {
    height: 47px;
    padding: 35px 0 0 20px;
    justify-content: center;
    @include flexCol;
  }

  .trade-header h2 {
    @include uRegular18;
    @include textLeft;
    padding-right: 7px;
  }

  .trade-services > .trade-table-header:nth-child(7),
  .trade-services > .trade-table-header:nth-child(12) {
    padding-top: 24px;
  }

  .trade-table-header {
    @include uMedium16;
    padding: 0px 19px 0px 19px;
  }

  .trade-services .ts-block {
    flex-wrap: wrap;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .trade {
    @include flexCol;
    align-items: center;
    position: relative;
    max-width: 1143px;
    margin: 0 auto;
  }

  .trade-header {
    @include sectionHeaders;
    margin-top: 0;
    h2 {
      @include uRegular24;
      margin-left: 19%;
    }
  }

  .trade-header:first-child {
    width: 435px;
    height: 62px;
    padding-top: 25px;
  }

  .trade-table-header {
    @include uMedium18;
    padding-bottom: 40px;
    padding-left: 43px;
  }

  .trade-services {
    width: 100%;
    max-width: 1199px;
  }
}

@media (min-device-width: 1200px) {
  .trade {
    @include flexCol;
    margin: 0 auto;
    min-width: 1143px;
    padding-left: 0;
  }

  .trade .info-block,
  .trade > div:nth-child(4),
  .trade .trade-services,
  .trade .price-list {
    span {
      color: #696B62;
    }
    align-self: end;
  }

  .trade .info-text {
    width: 100%;
    max-width: 816px;
    height: 265px;
    @include flexCol;
    justify-content: space-between;
    p {
      padding: 0;
      text-align: justify;
    }
  }

  .trade > div:nth-child(4) {
    width: 100%;
    max-width: 1143px;
    margin-bottom: 124px;
  }

  .trade-header {
    @include sectionHeaders;
    margin-top: 0;
    h2 {
      @include uRegular24;
      margin-left: 18%;
    }
  }

  .trade-header:first-child {
    width: 435px;
    height: 62px;
    padding-top: 25px;
  }

  .trade-table-header {
    @include uMedium24;
    padding-bottom: 40px;
  }

  .trade-services > .trade-table-header:nth-child(7),
  .trade-services > .trade-table-header:nth-child(12) {
    padding-top: 48px;
  }

  .trade-services {
    .trade-table-header,
    .ts-block {
      padding-left: 0;
      margin-left: 1%;
    }
  }
}

@media (min-device-width: 1440px) {
  .trade-header h2 {
    margin-left: 18%;
  }

  .trade .ts-price {
    width: auto;
    max-width: none;
  }

  .trade .ts-name {
    padding-right: 4vw;
  }

  .trade .contact-us {
    padding-left: 10%;
  }

  .price-list {
    span {
      color: #696B62;
    }
  }
}

@media (device-width: 1920px) {
  .trade-header h2 {
    margin-left: 80px;
  }

  .trade .trade-table-header,
  .trade-services h3 {
    margin-left: 1%;
  }

  .trade-services .ts-block {
    display: grid;
    grid-template-columns: 250px 633px 300px;
    margin: 0;
    padding-left: 1%;
    .ts-name {
      padding: 0;
      min-width: 100%;
    }
    .ts-service {
      padding: 0;
      margin-left: 14%;
      text-align: justify;
    }
    .ts-price {
      width: max-content;
      margin-left: -57px;
    }
  }

  .trade .contact-us {
    padding-left: 20%;
  }

  .price-list {
    span {
      color: #696B62;
    }
  }
}

@media (min-device-width: 1930px) {

  .price-list {
    span {
      color: #696B62;
    }
  }
  
  .App .trade .contact-us {
    padding-left: 0px;
    margin-left: 0px;
    align-self: center;
  }
}
