@import '../../styles/initstyles.scss';

.contact-us {
  @include flexCol;
  max-width: 1143px;
  margin: 0 auto;
  @media (min-device-width: 1200px) {
    margin: 0 0 100px 0;
    label {
      @include fAlignCenter;
      @include textLeft;
      flex-wrap: wrap;
      @include uRegular14;
      div {
        padding-right: 7px;
      }
    }
  }
  @media (max-device-width: 1199px) {
    margin: 0 0 100px 0;
    form {
    }
    label {
      @include fAlignCenter;
      @include textLeft;
      flex-wrap: wrap;
      @include uRegular14;
      div {
        padding-right: 7px;
      }
    }
  }
  @media (min-device-width: 1024px) {
    padding: 0 12px;
  }
  @media (max-device-width: 768px) {
    margin: 0 0 100px 0;
    padding: 0 20px;

    min-height: 100%;
    form {
    }
    label {
      @include fAlignCenter;
      @include textLeft;
      flex-wrap: wrap;
      @include uRegular14;
      div {
        padding-right: 7px;
      }
    }
  }
}

.notification-wrapper {
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 333px;
  min-width: 644px;
  background-color: $lightYellow;

  @media (max-device-width: 320px) {
    min-width: 0;
  }
}

.notification-container {
  font-family: Ubuntu;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 247px;
  min-height: 168px;
  > div {
    margin-bottom: 24px;
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}

.send-icon {
  width: 40px;
  height: 40px;
  background-color: #7a932c;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../src/styles/sendIcon.png');
}

.notification-title {
  color: #7A932C;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
}

.notification-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: center;
  max-width: 277px;
}

.form-zakaz {
  display: flex;
  @media (min-device-width: 1200px) {
    margin: 41px 0 80px;
    flex-direction: row;
    align-items: start;
  }
  @media (max-device-width: 1199px) {
    margin-top: 41px;
    flex-direction: row;
    align-items: start;
  }
  @media (max-device-width: 768px) {
    flex-direction: column;
    margin-top: 32px;
  }
  @media (max-device-width: 320px) {
    padding-bottom: 0;
  }
}

.form-zakaz .zakaz {
  @include buttonActions;
  @media (min-device-width: 1200px) {
    @include uRegular16;
    width: 502px;
    height: 52px;
  }
  @media (max-device-width: 1199px) {
    @include uRegular16;
    width: 502px;
    height: 52px;
  }
  @media (max-device-width: 768px) {
    width: 100%;
    @include uRegular16;
    height: 52px;
    margin-top: 40px;
  }
}

.call-header,
.call-paragraph {
  @include textLeft;
}

%inputs {
  outline: none;
  border: 1px solid $darkGrey;
  &:focus {
    border-color: $darkGreen;
  }
}

.usual {
  @extend %inputs;
}

.input-group {
  @media (min-device-width: 769px) {
    @include flexRow;
  }
}

.input-group input:nth-child(2n) {
  @media (max-device-width: 768px) {
    margin-top: 0;
  }
}

.input-group textarea {
  width: 100%;
  @extend %inputs;
  resize: none;
  @media (min-device-width: 1200px) {
    width: 502px;
    height: 126px;
  }
  @media (max-device-width: 1199px) {
    width: 502px;
    height: 126px;
  }
  @media (max-device-width: 768px) {
    width: 100%;
    margin-top: 10px;
    height: 100px;
  }
  @media (max-device-width: 480px) {
    width: 100%;
  }
}

.input-group input {
  @media (min-device-width: 1200px) {
    width: 399px;
    height: 48px;
    margin-bottom: 21px;
  }
  @media (max-device-width: 1199px) {
    width: 399px;
    height: 48px;
    margin-bottom: 21px;
  }
  @media (max-device-width: 768px) {
    width: 100%;
    height: 44px;
  }
}

.name-err,
.phone-err,
.policy-err {
  color: $redError;
  @include uRegular;
}

.ph-err {
  .PhoneInputInput {
    border: 1px solid $redError;
  }
}

.contactus-policy {
  display: flex;
  align-items: center;
  .policy {
    color: $darkGreen;
  }
}

.cp-err {
  input[type='checkox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $redError;
  }
  display: flex;
  align-items: center;
}

.PhoneInputCountry {
  display: none;
}

.PhoneInputInput {
  color: $black;
  &:focus {
    border: 1px solid $darkGreen;
    outline: none;
    color: $black;
  }
}

@media (max-device-width: 768px) {
  .contactus-policy {
    align-items: center;
  }

  .cp-err {
    @include uRegular14;
  }

  .call-header {
    @include uRegular18;
    padding: 30px 0;
  }

  .call-paragraph {
    @include uRegular14;
    padding-bottom: 32px;
    max-width: 969px;
  }

  .name-err,
  .phone-err,
  .text-err {
    font-size: 12px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .contactus-policy {
    width: 467px;
    > label {
      padding-left: 10px;
    }
  }

  .call-header {
    @include uRegular24;
    padding-bottom: 30px;
  }

  .call-paragraph {
    max-width: 969px;
    @include uRegular18;
    padding: 0px 0 40px;
  }

  .contact-group {
    @include flexCol;
    margin-right: 68px;
  }
}

@media (min-device-width: 1200px) {
  .contactus-policy {
    width: 467px;
  }

  .call-header {
    @include uRegular24;
    padding-bottom: 30px;
  }

  .call-paragraph {
    max-width: 969px;
    @include uRegular18;
    padding: 0 0 40px;
  }

  .contact-group {
    @include flexCol;
    margin-right: 68px;
  }
}
