.container {
  margin: 0 auto;
  max-width: 1143px;
  @media (max-device-width: 1024px) {
    min-width: 100%;
  }
  @media (max-device-width: 768px) {
    margin: 0;
    width: 100%;
  }
  @media (max-device-width: 480px) {
    padding: 0;
  }
}

.broad-container {
  margin: 0 auto;
  padding: 0;
  max-width: 1143px;

  @media (max-device-width: 1024px) {
    max-width: 100%;
  }
}
