@import '../../styles/initstyles.scss';

.prices-header {
  @include textLeft;
  font-weight: bold;
}

.prices-header:first-child {
  background: $green;
}

.break {
  padding: 0 5px;
}

.choose-esign {
  @include flexCol;
  @include textLeft;
  @media (min-device-width: 1200px) {
    padding: 83px 0 0 88px;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    padding: 80px 12px 60px 80px;
  }
  @media (max-device-width: 768px) {
    padding: 0 20px;
  }
}

.esign-options {
  @media (min-device-width: 1400px) {
    padding-left: 88px;
  }
  @media (min-device-width: 1200px) {
    padding: 80px 0 60px 88px;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    padding: 0 12px 12px 80px;
  }
  @media (max-device-width: 768px) {
    padding: 32px 19px 32px 19px;
  }
}

.prices .company-policy {
  @media (min-device-width: 1200px) {
    @include uRegular14;
    line-height: 140%;
    margin-left: 88px;
    padding-left: 0;
  }
  @media (max-device-width: 1199px) {
    margin-left: 0;
    padding: 40px 12px 100px 80px;
  }
  @media (max-device-width: 768px) {
    padding: 0 20px 100px;
  }
}

.esign-tools {
  @include flexCol;
  @include textLeft;
  @media (min-device-width: 1400px) {
    padding-left: 88px;
  }
  @media (min-device-width: 1200px) {
    padding-left: 88px;
  }
  @media (max-device-width: 1199px) {
    @include uRegular18;
    padding: 0 12px 12px 80px;
  }
  @media (max-device-width: 768px) {
    padding: 0 20px;
  }
}

.esign-price {
  display: flex;
  flex: 0 0 auto;
  @media (min-device-width: 1200px) {
    @include uMedium18;
  }
  @media (max-device-width: 1199px) {
    @include uMedium18;
  }
  @media (max-device-width: 768px) {
    font-size: 14px;
    width: 83px;
    padding-left: 50px;
    flex: 1 0 auto;
  }
  @media (max-width: 480px) {
    padding-left: 20px;
  }
}

.esign-tools .esign-price {
  @media (min-device-width: 1400px) {
    padding-left: 15%;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    margin-left: 100px;
  }
  @media (min-device-width: 1200px) {
    padding-left: 15%;
  }
  @media (max-device-width: 768px) {
    padding-left: 50px;
  }
  @media (max-width: 480px) {
    padding-left: 20px;
  }
}

.esign-tools label {
  @media (min-device-width: 1200px) {
    @include uRegular18;
  }
}

.prices {
  padding-left: 0;
  @media (min-device-width: 1920px) {
    padding-left: 0;
  }
  @media (min-device-width: 1600px) {
    padding-left: 0;
  }
  @media (min-device-width: 1400px) {
    @include maxWidth;
  }
  @media (min-device-width: 1200px) {
    align-self: center;
  }
  @media (max-device-width: 1024px) {
    max-width: 1000px;
  }
  @media (max-device-width: 768px) {
    min-width: 0;
    padding-left: 0;
  }
  @media (max-device-width: 320px) {
    padding-left: 0;
    padding-top: 0;
  }
}

.esign-box {
  @include flexRow;
  label {
    display: flex;
    p {
      padding-right: 10px;
      flex: 1 0 auto;
    }
  }
}

.esign-icon {
  cursor: pointer;
}

.notification-button {
  cursor: pointer;
  position: relative;
  background-color: #95a843;
  border-radius: 50%;
  height: 18px;
  width: 18px;

  @media (min-device-width: 768px) {
    &:hover {
      background-color: $darkGreen;
    }
  }
}

.notification-span {
  &:after {
    position: absolute;
    right: -1.4px;
    top: 2px;
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-image: url('../../../src/styles/exclamation.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.ecp-options {
  display: flex;
  align-items: center;
  flex: 1 0 70%;
}

.esign-info {
  border-radius: 2px;
  background-color: $lightYellow;
  color: $black;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: $darkGreen;
  }
}

.esign-numbers {
  @include flexRow;
}

.esign-summ {
  padding-left: 7px;
  @include fAlignCenter;
  background: $lightYellow;
}

.esign-button {
  @include buttonActions;
}

.ph-err {
  .PhoneInputInput {
    border: 1px solid $redError;
  }
}

.esign-type {
  @media (min-device-width: 1200px) {
    @include flexRow;
    justify-content: center;
    margin: 0 -15px;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
  }
  @media (max-device-width: 981px) {
    @include flexCol;
    margin: 0;
  }
}

.esign-item {
  @media (min-device-width: 1200px) {
    padding: 0 15px;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    max-width: 48%;
    padding: 0 10px;
  }
  @media (max-device-width: 981px) {
    max-width: 100%;
    padding: 20px 0 12px;
  }
}

.info-text {
  @media (min-device-width: 1200px) {
    width: 100%;
    max-width: 750px;
    height: 276px;
    @include flexCol;
    justify-content: space-around;  }
  @media (max-device-width: 1024px) {
    max-width: 520px;
  }
  @media (max-device-width: 768px) {
    max-width: 100%;
  }
}

.esign-summary {
  @media (min-device-width: 1400px) {
    padding-left: 44%;
  }
  @media (min-device-width: 1200px) {
    @include flexRow;
    align-items: center;
    padding-left: 47%;
    margin-top: 43px;
    height: 46px;
  }
  @media (max-device-width: 1024px) {
    justify-content: center;
  }
  @media (min-device-width: 769px) and (max-device-width: 1199px) {
    @include flexRow;
    justify-content: flex-end;
    align-items: center;
    margin-top: 43px;
    height: 46px;
  }
  @media (max-device-width: 768px) {
    padding: 0 20px;
    @include flexCol;
    margin-top: 43px;
  }
}

@media (max-device-width: 768px) {
  .prices-header {
    font-size: 18px;
    @include fAlignCenter;
    p {
      padding-right: 7px;
    }
  }

  .prices-header:first-child {
    width: 100%;
    height: 47px;
    padding-left: 19px;
  }

  .esign-header {
    @include uMedium16;
  }

  .esign-box {
    padding: 12px 0 12px;
    label {
      @include uRegular14;
      margin-left: 5px;
      padding-left: 7px;
      width: auto;
      line-height: initial;
      flex: 1 0 70%;
      @media (max-width: 480px) {
        p {
          flex: 1;
          width: 100%;
        }
      }
    }
    .esign-icon {
      width: 24px;
      height: 24px;
    }
  }

  .esign-info {
    bottom: 0;
    padding: 20px 10px;
    left: -160px;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    position: absolute;
    min-width: 220px;
  }

  .esign-about {
    font-size: 12px;
    color: #696B62;
    line-height: initial;
    max-width: 80%;
  }

  .esign-options h3 {
    padding-bottom: 20px;
  }

  .esign-tools h3 {
    padding-bottom: 32px;
  }

  .esign-numbers {
    display: flex;
    align-items: center;
  }

  .esign-numbers:nth-child(1) {
    @include uRegular18;
    font-weight: bold;
    padding-bottom: 15px;
  }

  .esign-summ {
    @include uRegular18;
    font-weight: normal;
    border-radius: 2px;
    width: 170px;
    height: 46px;
    margin-left: 16px;
  }

  .esign-button {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    font-size: 16px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 1199px) {
  .esignature {
  @include flexCol;
    align-items: center;
    position: relative;
  }

  .prices-header {
    @include sectionHeaders;
    font-size: 24px;
    p {
      margin-left: 5%;
    }
  }

  .prices-header {
    width: 404px;
    height: 62px;
    padding-top: 25px;
    left: 0;

    > p {
      margin-left: 20%;
    }
  }

  .esign-header {
    @include uMedium18;
    padding: 20px 0;
  }

  .esign-box {
    @include flexRow;
    align-items: center;
    padding: 15.5px 0;
    label {
      margin-left: 32px;
      max-width: 100%;
    }
  }

  .ecp-type {
    @include uMedium18;
    width: 350px;
  }

  .ecp-options,
  .ecp-tools {
    width: 100%;
    align-items: center;
  }

  .esign-about {
    color: #696B62;
    padding-left: 55px;
    max-width: 800px;
    @include uRegular14;
    line-height: 140%;
    text-align: justify;
  }

  .esign-options label {
    @include uRegular18;
  }

  .esign-summary .esign-numbers {
    align-items: center;
  }

  .esign-summary > div:nth-child(1) {
    @include uMedium24;
    font-size: 24px;
  }

  .esign-summ {
    width: 170px;
    height: 52px;
    margin-left: 6vw;
    @include uRegular;
    font-size: 20px;
  }

  .esign-button {
    width: 270px;
    height: 52px;
    @include uRegular16;
    margin-left: 4vw;
  }
}

@media (min-device-width: 1200px) {
  .esignature {
    @include flexCol;
  }

  .esignature .info-block,
  .esignature .price-list,
  .esignature {
    align-self: center;
  }

  .info-text p {
    padding: 0;
  }

  .prices-header {
    @include sectionHeaders;
    padding-left: 88px;
  }

  .prices-header:first-child {
    width: 404px;
    height: 62px;
    padding-top: 25px;
  }

  .esign-header {
    @include uMedium18;
    padding-bottom: 39px;
  }

  .esign-box {
    align-items: center;
    padding: 15.5px 0;
    label {
      margin-left: 32px;
      flex: 1 0 auto;
    }
  }

  .ecp-type {
    width: 350px;
    @include uMedium18;
  }

  .ecp-options,
  .ecp-tools {
    .withoutCheckbox {
      padding-left: 25px;
      align-items: center;
      @media (max-device-width: 320px) {
        padding-left: 30px;
      }
    }
    width: 620px;
  }

  .esign-about {
    color: #696B62;
    padding-left: 55px;
    max-width: 580px;
    line-height: 140%;
    @include uRegular14;
  }

  .esign-options {
    label {
      @include uRegular18;
    }
    .esign-price {
      padding-left: 72px;
    }
  }

  .esign-box {
    @include flexRow;
  }

  .esign-summary .esign-numbers {
    align-items: center;
  }

  .esign-summary > div:nth-child(1) {
    @include uMedium24;
  }

  .esign-summ {
    @include uRegular;
    font-size: 20px;
    padding-left: 12px;
    font-weight: normal;
    border-radius: 2px;
    width: 170px;
    height: 52px;
    margin-left: 6vw;
  }

  .esign-button {
    width: 270px;
    height: 52px;
    @include uRegular16;
    margin-left: 4vw;
  }
}

@media (min-device-width: 1400px) {
  .choose-esign .esign-header {
    padding-left: 3%;
  }
}

@media (device-width: 1920px) {
  .prices .esign-summary {
    padding-left: 43%;
  }
}

@media (min-device-width: 2559px) {
  .prices .esign-summary {
    padding-left: 40%;
  }
}

@media (max-device-width: 320px) {
  .contact-us {
    margin: 0;
    padding: 0 20px 100px;
  }
  .ecp-options {
    .withoutCheckbox {
      padding-left: 24px;
      align-items: center;
    }
  }
  .esign-button {
    width: 280px;
  }
}
